<template>
    <div>
        <div
            class="row no-gutters"
            style="background-image: url('/images/headers/Vest-Divider-1400x525.jpg'); height: 30vh; position: relative; background-position: center center;background-repeat:no-repeat; background-size: cover;"
        >
            <h1
                class="ml-3 text-light"
                style="position: absolute; bottom: 2rem; left: 2rem;"
            >
                Literature
            </h1>
        </div>
        <div class="row no-gutters p-5">
            <div class="col-md-12">
                <h2 class="text-uppercase literature-heading">
                    product catalogs
                </h2>
                <div id="literature-container" class="d-flex flex-wrap justify-content-center">
                    <div
                        class="literature d-flex flex-column"
                        v-for="(literature, index) in productCatalogs"
                        :key="'lit' + index"
                    >
                        <router-link
                            :to="'docs/' + literature.path"
                            target="_blank"
                        >
                            <img
                                :src="'/images/' + literature.image_path"
                                alt=""
                                class="mb-3"
                            />
                        </router-link>
                        <router-link
                            :to="'docs/' + literature.path"
                            target="_blank"
                            class="btn btn-primary w-75"
                            >DOWNLOAD</router-link
                        >
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <h2 class="text-uppercase pt-5 literature-heading">
                    brochures &amp; sell sheets
                </h2>
                <div id="literature-container" class="d-flex flex-wrap justify-content-center">
                    <div
                        class="literature d-flex flex-column"
                        v-for="(literature, index) in brochures"
                        :key="'brochure' + index"
                    >
                        <router-link
                            :to="'docs/' + literature.path"
                            target="_blank"
                        >
                            <img
                                :src="'/images/' + literature.image_path"
                                alt=""
                                class="mb-3"
                            />
                        </router-link>
                        <router-link
                            :to="'docs/' + literature.path"
                            target="_blank"
                            class="btn btn-primary w-75"
                            >DOWNLOAD</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {};
    },
    computed: {
        ...mapState(["literatures"]),
        productCatalogs() {
            return this.literatures.filter(
                literature => literature.category == "products"
            );
        },
        brochures() {
            return this.literatures.filter(
                literature => literature.category == "brochures-sell-sheets"
            );
        }
    }
};
</script>

<style scoped lang="scss">
h1 {
    font-size: 3.5rem;
}
h2 {
    font-weight: 100;
}

.literature {
    max-width: 250px;
    margin: 0.5rem;
    transition: all 0.4s ease-out;
    align-items: center;
    img {
        max-width: 100%;
        max-height: 281px;
    }

}
.literature-heading{
        color:teal;
        font-weight:600;
        text-align: center;
    }
</style>
