import VueRouter from 'vue-router';
import Home from '@c/pages/Home';
import Category from '@c/pages/Category';
import Product from '@c/product/Product';
import Literature from '@c/pages/LiteraturePage';
import Merchandising from '@c/pages/Merchandising';
import Contact from '@c/pages/Contact';
import Dashboard from '@c/pages/Dashboard';
import MyTools from '@c/dashboard/MyTools';
import Account from '@c/dashboard/Account';
import EditUser from '@c/dashboard/EditUser';
import UserList from '@c/dashboard/UserList';
import ChangePassword from '@c/dashboard/ChangePassword';
import PageNotFound from '@c/pages/PageNotFound';
import DefaultDashboard from '@c/dashboard/DefaultDashboard';
import ResetPassword from './auth/ResetPassword';
import ForgotPassword from './auth/ForgotPassword';
import Checkout from '@c/payments/Checkout';
import Summary from '@c/payments/Summary';
import PrivacyPolicy from '@c/pages/PrivacyPolicy';
import CookiePolicy from '@c/pages/CookiePolicy';
import CustomerLogin from './auth/CustomerLogin';
import TechnicalSupport from '@c/pages/TechnicalSupport';


function guardMyroute(to,from,next)
    {
    if(localStorage.getItem('isLoggedIn')=="false" || !localStorage.getItem('isLoggedIn')){
        next('/auth/login'); // go to '/login';
    }else{
        next();
    }
}

function guardMyTools(to,from,next)
    {
    if(localStorage.getItem('approval')=="0"){
        next('/dashboard/'); // go to homepage;
    }else{
        next();
    }
}

function guardMyUserList(to,from,next)
    {
        if(localStorage.getItem('userRole')!="admin"){
            next('/dashboard/'); // go to homepage;
        }else{
            next();
        }
}
const routes = [
    {
        path: "/",
        component: Home,
        name: 'home'
    },
    {
        path: "/products",
        component: Category,
        name: 'products',
        meta: {
            title: 'Products | Safety Works'
        }
    },
    {
        path: "/product/:id",
        component: Product,
        name: 'product'
    },
    {
        path: "/literature",
        component: Literature,
        name: 'literature',
        meta: {
            title: 'Literature | Safety Works'
        }
    },
    {
        path: "/contact",
        component: Contact,
        name: 'contact',
        meta: {
            title: 'Contact Us | Safety Works'
        }
    },
    {
        path: "/merchandising",
        component: Merchandising,
        name: 'merchandising'
    },
    {
        path: "/technical-support",
        component: TechnicalSupport,
        name: 'tech-support',
        meta: {
            title: 'Tech Support | Safety Works'
        }
    },
    {
        path: "/checkout",
        name: "order.checkout",
        component: Checkout
    },
    {
        path: "/summary",
        name: "order.summary",
        component: Summary
    },
    {
        path: "/privacy-policy",
        name: 'privacy',
        component: PrivacyPolicy
    },
    {
        path: "/cookies",
        name: 'privacy',
        component: CookiePolicy
    },
    {
        path: "/dashboard/",
        component: Dashboard,

        beforeEnter:guardMyroute,
        children: [
            {
                path: "/dashboard/myTools",
                component: MyTools,
                name: 'myTools',
                beforeEnter:guardMyTools,
            },
            {
                path: "/dashboard/myAccount/",
                component: Account,
                name: 'myAccount'
            },
            {
                path: "/dashboard/account/:id",
                component: EditUser,
                name: 'editUser'
            },
            // {
            //     path: "/dashboard/productImages",
            //     component: ProductImage,
            //     name: 'productImages'
            // },
            {
                path: "/dashboard/userList",
                component: UserList,
                name: 'userList',
                beforeEnter:guardMyUserList,
            },
            {
                path: "/dashboard/changePassword",
                component: ChangePassword,
                name: "changePassword",
            },
            {
                path:'',
                name:'dashboard',
                component: DefaultDashboard,
            }

        ]
    },

    {
        path: "/auth/customer/login",
        component: CustomerLogin,
        name: "customerLogin",
    },
    {
        path: "/auth/customer/login/verify/",
        component: CustomerLogin,
        name: "customerVerify",
    },

    {
        path: "/auth/login",
        component: require("./auth/Login").default,
        name: "login",
    },
    // {
    //     path: "/auth/register",
    //     component: require("./auth/Register").default,
    //     name: "register",
    // },
    {
        path: "/auth/login/verify/",
        component: require("./auth/Login").default,
        name: "verify",
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ForgotPassword,
        // meta: {
        //   auth:false
        // }
      },
      {
        path: '/reset-password/:token',
        name: 'reset-password-form',
        component: ResetPassword,
        meta: {
          auth:false
        }
      },

    {
        path: '/:catchAll(.*)',
        component: PageNotFound,
        name: 'pathNotFound'
    },



]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
          } else {
            return { x: 0, y: 0 }
          }
    }
});

router.beforeEach((toRoute, fromRoute, next) => {
    window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Safety Works - Safety is All We Do';

    next();
  })

export default router;
