<template>
    <div>
        <div class="row mt-3" v-if="product">
            <!-- Breadcrumbs -->
            <div class="col-md-12 m-4" id="prod-breadcrumbs" v-if="category">
                <router-link
                    class="cat-link"
                    :to="{
                        name: 'products',
                        query: { cat: category.slug }
                    }"
                    ><span class="text-uppercase"
                        >{{ category.name }} /
                    </span></router-link
                >
                <router-link
                    v-if="subcategory"
                    class="cat-link"
                    :to="{
                        name: 'products',
                        query: { cat: category.slug, subcat: subcategory.slug }
                    }"
                    ><span class="text-uppercase"
                        >{{ subcategory.name }} /
                    </span></router-link
                >
                <span
                    class="prod-name text-uppercase"
                    v-if="product"
                    v-html="product.short_desc"
                >
                    /
                </span>
            </div>
            <!-- End Breadcrumbs -->
        </div>
        <div class="row mt-4" v-if="product">
            <div
                id="prod-images"
                class="col-md-12 col-lg-6 p-4 justify-content-center align-items-center"
                v-if="product.images"
            >
                <div class="w-100" id="product-slider">
                    <VueSlickCarousel v-bind="settings">
                        <div
                            v-for="(image, index) in product.images"
                            :key="'image' + index"
                            class="slide-content"
                        >
                            <div
                                :id="'slide' + index"
                                class="w-100 position-relative d-flex justify-content-center image-slider-container"
                            >
                                <b-img
                                    :src="`/images/products/${image.path}`"
                                    class="product-image"
                                ></b-img>
                            </div>
                        </div>
                    </VueSlickCarousel>
                </div>
            </div>
            <div
                class="col-xs-12 col-sm-8 offset-sm-2 col-lg-5 offset-lg-0 p-5"
                v-if="product"
                id="product-details"
            >
                <h2 class="text-uppercase">{{ product.brand }}</h2>
                <h4 class="w-75" v-html="product.short_desc"></h4>
                <h4 class="text-primary mb-4">{{ product.prod_id }}</h4>
                <p v-if="useCart" v-text="formatCurrency(product.price)"></p>
                <button
                    class="btn btn-success"
                    @click="$store.commit('addToCart', product)"
                    v-if="useCart"
                >
                    Add To Cart
                </button>
                <p class="prod-features-title font-weight-bold">
                    FEATURES
                </p>
                <div v-html="product.description" class="ml-3"></div>
            </div>
        </div>

        <!-- <div class="row mt-4">
            <div class="col-md-12">
                <b-tabs content-class="p-5" v-if="product">
                    <b-tab title="Description" active>
                        <p>{{ product.short_desc }}</p>
                    </b-tab>
                    <b-tab title="Features" v-if="product.features">
                        <ul>
                            <li
                                v-for="feature in product.features"
                                :key="'feature' + feature.id"
                            >
                                {{ feature.description }}
                            </li>
                        </ul>
                    </b-tab>
                </b-tabs>
            </div>
        </div> -->
    </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapState } from "vuex";

export default {
    data() {
        return {
            loading: false,
            slide: 0,
            interval: 0,
            features: null,
            subcategory: null,
            settings: {
                dots: true,
                controls: true,
                dotsClass: "slick-dots custom-dot-class",
                edgeFriction: 0.35,
                infinite: true,
                speed: 500,
                autoplay: false,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        };
    },
    computed: {
        ...mapState(["categories", "products", "useCart"]),
        category() {
            for (var i = 0; i < this.categories.length; i++) {
                if (this.categories[i].id == this.product.category_id) {
                    return this.categories[i];
                } else {
                    for (
                        var j = 0;
                        j < this.categories[i].children.length;
                        j++
                    ) {
                        if (
                            this.categories[i].children[j].id ==
                            this.product.category_id
                        ) {
                            this.subcategory = this.categories[i].children[j];
                            return this.categories[i];
                        }
                    }
                }
            }
            return null;
        },
        product() {
            if (this.products) {
                return this.products.find(
                    product => product.id == this.$route.params.id
                );
            }
        }
    },
    methods: {
        getPath(path) {
            return `/images/${path}`;
        },
        formatCurrency(price) {
            price = price / 100;
            return price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
            });
        }
    },
    created() {
        if (this.product == {}) {
            this.$store.dispatch("setProduct", this.$route.params.id);
        }
    },
    components: { VueSlickCarousel }
};
</script>

<style lang="scss">
#product-details {
    h2 {
        text-transform: unset;
        margin-bottom: 0.3rem;
        font-family: "Acumin Pro Condensed", Helvetica, Arial, sans-serif;
        font-size: 2.5rem;
    }
    h4 {
        font-size: 1.15rem;
        font-weight: normal;
        font-family: "Acumin Pro Condensed", Helvetica, Arial, sans-serif;
    }
    p {
        font-weight: normal;
        font-family: "Acumin Pro", Helvetica, Arial, sans-serif;
    }
    a {
        color: teal;
        &:hover {
            color: #111;
        }
    }
}

#product-slider {
    .slick-slider {
        button.slick-arrow.slick-prev,
        button.slick-arrow.slick-next {
            z-index: 100 !important;
            &:before {
                font-size: 2rem !important;
            }
        }
        button.slick-arrow.slick-prev {
            left: 1rem !important;
            &:before {
                color: teal;
            }
        }
        button.slick-arrow.slick-next {
            right: 1rem !important;
            &:before {
                color: teal;
            }
        }
    }
    .slide-content {
        .image-slider-container {
            img.product-image {
                min-width: 90%;
                max-width: 100%;
                height: 75vh;
                object-fit: contain;
                @media screen and (max-width: 974px) {
                    height: unset;
                }
            }
        }
    }
}
#prod-breadcrumbs {
    font-size: 0.8rem;
    font-weight: normal;
    a {
        color: #333;
        padding: 0.4rem 0.2rem;
        &:hover {
            color: teal;
        }
    }
}
</style>
