var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"w-100"},[_c('VueSlickCarousel',_vm._b({},'VueSlickCarousel',_vm.settings,false),[_c('div',{staticClass:"w-100 position-relative slide",attrs:{"id":"slide1"}},[_c('div',{staticClass:"content-container position-absolute"},[_c('div',{staticClass:"text-container"},[_c('h1',[_vm._v("\n                                SAFETY IS ALL WE DO\n                            ")]),_vm._v(" "),_c('p',[_vm._v("\n                                Our focus is exclusively on personal safety.\n                                It’s all we do, so we’re best positioned to\n                                assist retail professionals in selecting the\n                                best safety products for specific market\n                                needs.\n                            ")])])]),_vm._v(" "),_c('img',{attrs:{"src":"/images/headers/index-main-800-1-1400x700.jpg","alt":"Background image of construction worker reading level on metal beam"}})])])],1)]),_vm._v(" "),(_vm.$store.state.useProducts)?_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"industry col-lg-6 col-xl-4",attrs:{"id":"ear-protection"}},[_c('div',{staticClass:"industry-text"},[_c('h3',[_vm._v("BRING THE NOISE")]),_vm._v(" "),_c('p',[_vm._v("\n                        Our digital ear muffs help limit sound to below OSHA\n                        dangerous threshold levels, even when connected to a\n                        mobile device.\n                    ")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-outline-light text-uppercase",attrs:{"to":{
                            name: 'products',
                            query: { cat: 'hearing-protection' }
                        }}},[_vm._v("View Products")])],1)]),_vm._v(" "),_c('div',{staticClass:"industry col-lg-6 col-xl-4",attrs:{"id":"head-protection"}},[_c('div',{staticClass:"industry-text"},[_c('h3',[_vm._v("EXCEPTIONAL STYLE & FIT")]),_vm._v(" "),_c('p',[_vm._v("\n                        Easy to use wheel ratchet adjustment system creates\n                        a firm, comfortable fit with added security.\n                    ")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-outline-light text-uppercase",attrs:{"to":{
                            name: 'products',
                            query: { cat: 'head-face-protection' }
                        }}},[_vm._v("View Products")])],1)]),_vm._v(" "),_c('div',{staticClass:"industry col-lg-12 col-xl-4",attrs:{"id":"eye-protection"}},[_c('div',{staticClass:"industry-text"},[_c('h3',[_vm._v("STYLE MEETS SAFETY")]),_vm._v(" "),_c('p',[_vm._v("\n                        We offer a huge selection of safety glasses and\n                        goggles for every project and price point.\n                    ")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-outline-light text-uppercase",attrs:{"to":{
                            name: 'products',
                            query: { cat: 'eye-protection' }
                        }}},[_vm._v("View Products")])],1)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }