<template>
    <div
        class="mobile-filters-container d-xl-none d-lg-block bg-secondary text-light p-3 w-100"
    >
        <div class="w-100 applied-filters mt-3" v-if="filtersApplied">
            <div v-if="brandFilterApplied">
                <h5 class="text-center">Brands</h5>
                <ul>
                    <li
                        v-for="brand in selectedBrands"
                        :key="'brand' + brand.id"
                        class="text-center filter-list-item"
                        @click.prevent="selectBrand(brand)"
                    >
                        <i class="fas fa-times-circle mr-3"></i>
                        {{ brand }}
                    </li>
                </ul>
            </div>
            <div v-if="applicationFilterApplied" class="mt-3">
                <h5 class="text-center">Applications</h5>
                <ul>
                    <li
                        v-for="app in selectedApplications"
                        :key="'app' + app.id"
                        class="text-center filter-list-item"
                        @click.prevent="selectApplication(app)"
                    >
                        <i class="fas fa-times-circle mr-3"></i>
                        {{ app }}
                    </li>
                </ul>
            </div>
        </div>
        <div id="mobile-filters" class="collapse mt-4">
            <div class="row border-top pt-2">
                <div class="col-6">
                    <h5 class="text-center">Brands</h5>
                    <table class="filters-table w-100">
                        <tr
                            v-for="(brand, index) in brands"
                            :key="'brand' + index"
                        >
                            <td v-if="brandInFiltered(brand)">
                                <button
                                    class="btn btn-filter btn-sm btn-block mb-1"
                                    @click="selectBrand(brand)"
                                    :class="{
                                        active: brandActive(brand)
                                    }"
                                >
                                    {{ brand }}
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-6">
                    <h5 class="text-center">Applications</h5>
                    <table class="filters-table w-100">
                        <tr
                            v-for="(application, index) in applications"
                            :key="'app' + index"
                        >
                            <td v-if="applicationInFiltered(application)">
                                <button
                                    class="btn btn-filter btn-sm btn-block mb-1"
                                    @click="selectApplication(application)"
                                    :class="{
                                        active: applicationActive(application)
                                    }"
                                >
                                    {{ application }}
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState([
            "applications",
            "brands",
            "selectedBrands",
            "selectedApplications",
            "categoryFilteredProducts"
        ]),
        filtersApplied() {
            return this.$route.query.brands || this.$route.query.applications;
        },
        brandFilterApplied() {
            return this.$route.query.brands;
        },
        applicationFilterApplied() {
            return this.$route.query.applications;
        }
    },
    mounted() {
        document.addEventListener("DOMContentLoaded", function(event) {
            $("#filters-button").on("click", function() {
                $("#filters-button")
                    .toggleClass("fa-times")
                    .toggleClass("fa-filter")
                    .toggleClass("fas")
                    .toggleClass("fa");
            });
        });
    },
    methods: {
        selectBrand(brand) {
            this.$store.dispatch("setSelectedBrands", brand);
        },
        brandActive(brand) {
            return this.$route.query.brands
                ? this.$route.query.brands.split(",").includes(brand)
                : false;
        },
        applicationActive(app) {
            return this.selectedApplications
                ? this.selectedApplications.includes(app)
                : false;
        },
        selectApplication(app) {
            this.$store.dispatch("setSelectedApplications", app);
        },
        brandInFiltered(brand) {
            if (this.categoryFilteredProducts.length > 1) {
                return this.categoryFilteredProducts.some(
                    product => product.brand == brand
                );
            } else return true;
        },
        applicationInFiltered(app) {
            if (this.categoryFilteredProducts.length > 1) {
                return this.categoryFilteredProducts.some(product =>
                    product.applications.includes(app)
                );
            } else return true;
        }
    },

    watch: {
        selectedBrands: function(newValue, prevValue) {
            let brands = this.selectedBrands.join();
            var category, subcategory, applications;
            category = this.$route.query.cat ?? null;
            subcategory = this.$route.query.subcat ?? null;
            applications = this.$route.query.applications ?? null;
            if (category && subcategory && applications) {
                this.$router
                    .push({
                        query: {
                            cat: category,
                            subcat: subcategory,
                            applications: applications,
                            brands: brands
                        }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (category && subcategory && !applications) {
                this.$router
                    .push({
                        query: {
                            cat: category,
                            subcat: subcategory,
                            brands: brands
                        }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (category && !subcategory && !applications) {
                this.$router
                    .push({
                        query: { cat: category, brands: brands }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (category && !subcategory && applications) {
                this.$router
                    .push({
                        query: {
                            cat: category,
                            brands: brands,
                            applications: applications
                        }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (!category && !subcategory && applications) {
                this.$router
                    .push({
                        query: { applications: applications, brands: brands }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (!category && !subcategory && !applications) {
                this.$router
                    .push({
                        query: { brands: brands }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            }
            if (this.selectedBrands.length < 1) {
                let query = Object.assign({}, this.$route.query);
                delete query.brands;
                this.$router.replace({ query });
            }
        },
        selectedApplications: function(newValue, prevValue) {
            let applications = this.selectedApplications.join();
            var category, subcategory, brands;
            category = this.$route.query.cat ?? null;
            subcategory = this.$route.query.subcat ?? null;
            brands = this.$route.query.brands ?? null;
            if (category && subcategory && brands) {
                this.$router
                    .push({
                        query: {
                            cat: category,
                            subcat: subcategory,
                            applications: applications,
                            brands: brands
                        }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (category && subcategory && !brands) {
                this.$router
                    .push({
                        query: {
                            cat: category,
                            subcat: subcategory,
                            applications: applications
                        }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (category && !subcategory && !brands) {
                this.$router
                    .push({
                        query: { cat: category, applications: applications }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (!category && !subcategory && brands) {
                this.$router
                    .push({
                        query: { applications: applications, brands: brands }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (!category && !subcategory && !brands) {
                this.$router
                    .push({
                        query: { applications: applications }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            }
            if (this.selectedApplications.length < 1) {
                let query = Object.assign({}, this.$route.query);
                delete query.applications;
                this.$router.replace({ query });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
label {
    &:hover {
        cursor: pointer;
    }
}
.btn-filter {
    outline: 1px solid #ccc;
    color: white;
    font-size: 0.8rem !important;
    margin-bottom: 0.5rem !important;
    &:hover,
    &:focus {
        background: #333;
        color: white;
    }
}
.active {
    background: #333 !important;
}
.filter-list-item {
    list-style: none;
}
</style>
