import { isLoggedIn, logOut, getUserEmail } from "./components/utils/auth";
export default {

    state: {
        isLoggedIn: false,
        userEmail:null,
        user: {},
        isCustomerLoggedIn:false,
        customerEmail:null,
        customer:{},
        products: [],
        categories: [],
        literatures: [],
        brands: [],
        applications: [],
        product: {},
        selectedBrands: [],
        selectedApplications: [],
        storedCategory: {},
        categoryFilteredProducts: [],
        currentFiltered: [],
        usersList:[],
        filteredUsersList: [],
        message:null,
        success:false,
        error: false,
        cart: [],
        order: {},
        useCart: false,
        useProducts: true,
        useAdmin: false,
        useLocale: false

    },
    mutations: {
        setUser(state,payload){
            state.user=payload;
        },
        setUsersList(state,payload){
            state.usersList=payload;
        },
        setFilteredUsersList(state,payload){
            state.filteredUsersList=payload;
        },
        setLoggedIn(state,payload){
            state.isLoggedIn=payload;
        },
        setUserEmail(state,payload){
            state.userEmail=payload;
        },
        setCustomerLoggedIn(state,payload){
            state.isCustomerLoggedIn=payload;
        },
        setCustomer(state,payload){
            state.customer=payload;
        },
        setCustomerEmail(state,payload){
            state.customerEmail=payload;
        },
        loadBrands(state, payload) {
            state.brands = payload;
        },
        loadApplications(state, payload) {
            state.applications = payload;
        },
        loadProducts(state, payload) {
            state.products = payload;
        },
        loadCategories(state, payload) {
            state.categories = payload;
        },
        loadLiterature(state, payload) {
            state.literatures = payload;
        },
        setProduct(state, payload) {
            state.product = payload;
        },
        clearSelectedBrands(state) {
            state.selectedBrands = [];
        },
        setSelectedApplications(state, payload) {
            state.selectedApplications = payload;
        },
        clearSelectedApplications(state) {
            state.selectedApplications = [];
        },
        setStoredCategory(state, payload) {
            state.selectedCategory = payload;
        },
        setCategoryFilteredProducts(state, payload) {
            state.categoryFilteredProducts = payload;
        },
        setCurrentFiltered(state, payload) {
            state.currentFiltered = payload;
        },
        addToCart(state, product) {
            let productInCartIndex = state.cart.findIndex(item => item.id == product.id);
            if (productInCartIndex !== -1) {
                state.cart[productInCartIndex].quantity++;
                return;
            }

            product.quantity = 1;
            state.cart.push(product);
        },
        removeFromCart(state, index) {
            state.cart.splice(index, 1);
        },
        updateOrder(state, order) {
            state.order = order
        },
        updateCart(state, cart) {
            state.cart = cart;
        }
    },
    getters:{
        getUser:state=>{
            return state.user;
        },
        getCustomer:state=>{
            return state.customer;
        },
        selectedProduct: state => {
            return state.product;
        }
    },
    actions: {
        async loadStoredState(context){
            context.commit("setLoggedIn", isLoggedIn());
            context.commit("setUserEmail", getUserEmail());

            // loading user detail if the user is logged in
            if(isLoggedIn()){
                try {
                    const user= (await axios.get('/user')).data;
                    localStorage.setItem("approval", user.approve);
                    localStorage.setItem("userRole", user.role);

                } catch (error) {
                    dispatch("logout");
                }
            }
        },
        loadCustomerStoredState(context){
            context.commit("setCustomerLoggedIn", isCustomerLoggedIn());
            context.commit("setCustomerEmail", getCustomerEmail());
        },
        loadUsersList({ commit}){
            axios.get(`/api/user`)
            .then(response=> {
                commit("setUsersList",response.data);
                const filteredList=response.data.filter(user=>{
                    return user.archive==0;
                });
                commit("setFilteredUsersList",filteredList);
            });
        },

        archiveUser({ commit, state, dispatch }, payload){
            axios.post(`/api/user/archiveUser`, payload).then((response) => {
                state.message="The user has been archived.";
                state.success=true;
                state.error=false;
                var self=state;
                setTimeout(function(){
                    self.success=false;
                }, 3000);
                dispatch("loadUsersList");

            });
        },
        approveUser({ commit, state, dispatch }, payload){
            if(payload.role=='customer'){
                state.message="Please choose a different user role.";
                state.error=true;
                state.success=false;
                var self=state;
                setTimeout(function(){
                    self.error=false;
                }, 3000);

            }else{
                axios.post(`/api/user/approveUser`, payload).then((response) => {
                    state.message="The user has been approved";
                    state.success=true;
                    state.error=false;
                    var self=state;
                    setTimeout(function(){
                        self.success=false;
                    }, 3000);
                    axios.get(`/api/user`).then(response=> {
                        commit("setUsersList",response.data);
                        const filteredList=response.data.filter(user=>{
                            return user.role=='customer' && user.archive==0;
                        });
                        commit("setFilteredUsersList",filteredList);
                    });

                });
            }

        },
        restoreUser({ commit, state, dispatch }, payload){
            if(payload.role=='customer'){
                state.message="Please choose a different user role.";
                state.error=true;
                state.success=false;
                var self=state;
                setTimeout(function(){
                    self.error=false;
                }, 3000);
            }else{
                axios.post(`/api/user/approveUser`, payload).then((response) => {
                    state.message="The user has been approved.";
                    state.success=true;
                    state.error=false;
                    var self=state;
                    setTimeout(function(){
                        self.success=false;
                    }, 3000);
                    axios.get(`/api/user`).then(response=> {
                        commit("setUsersList",response.data);
                        const filteredList=response.data.filter(user=>{
                            return user.archive==1;
                        });
                        commit("setFilteredUsersList",filteredList);
                    });

                });


            }

        },
        async loadUser({commit, dispatch}){
            if(isLoggedIn()){
                try {
                    const user= (await axios.get('/user')).data;
                    commit("setUserEmail", user.email);
                    commit("setUser", user);
                    commit("setLoggedIn", true);


                } catch (error) {
                    dispatch("logout");
                }
            }
        },
        logout({commit}){
            commit("setUser", {});
            commit("setLoggedIn", false);
            commit("setUserEmail", null);
            logOut();
        },

        async loadCustomer({commit, dispatch}){
            if(isCustomerLoggedIn()){
                try {
                    const user= (await axios.get('/customer')).data;
                    commit("setCustomerEmail", customer.email);
                    commit("setCustomer", customer);
                    commit("setCustomerLoggedIn", true);

                } catch (error) {
                    dispatch("customerLogout");
                }
            }
        },
        customerLogout({commit}){
            commit("setCustomer", {});
            commit("setCustomerLoggedIn", false);
            commit("setCustomerEmail", null);
            customerLogOut();
        },

        loadProducts({ commit, state }) {
            axios.get("/api/products").then(response => {
                commit('loadProducts', response.data.data);
                commit('loadBrands', [...new Set(response.data.data.map(x => x.brand))].sort());
                const applications = ['Automotive', 'Farm & Ranch', 'Hardware Professional', 'Lawn & Garden'];
                commit('loadApplications', applications);
            });
        },
        loadCategories({ commit }) {
            axios.get("/api/categories").then(response => {
                commit('loadCategories', (response.data));

            });
        },
        loadLiterature({ commit }) {
            axios.get(`/api/literatures`).then(response => {
                commit('loadLiterature', response.data);
            });
        },
        setProduct({ commit, state }, payload) {
            const result = state.products.find(product => product.id === payload);
            commit('setProduct', result);
            localStorage.setItem('product', JSON.stringify(state.product));

        },
        storeCategory({ commit, state },payload) {
            commit('setStoredCategory', payload);
            localStorage.setItem('storedCategory', JSON.stringify(payload));
        },
        loadStoredProduct({ commit }) {
            const product = localStorage.getItem('product');
            if (product) {
                commit('setProduct', JSON.parse(product));
            }
        },
        loadStoredCategory({ commit }) {
            const category = localStorage.getItem('storedCategory');
            if (category) {
                commit('setStoredCategory', JSON.parse(category));
            }
        },
        setSelectedBrands({ commit, state }, payload) {
            let brands = state.selectedBrands;

            if ( brands.includes(payload)) {
                for (var i = 0; i < brands.length; i++){
                    if (brands[i] == payload) {
                        brands = brands.splice(i, 1);
                    }
                }

            } else {
                brands.push(payload);
            }
            // commit('setSelectedBrands', brands);
        },
        setSelectedApplications({ commit, state }, payload) {
            let applications = state.selectedApplications;
            if (applications.includes(payload)) {
                for (var i = 0; i < applications.length; i++){
                    if (applications[i] == payload) {
                        applications = applications.filter(
                            (application) => application !== payload)
                    }
                }
            } else {
                applications.push(payload);
            }
            commit('setSelectedApplications', applications);
        },
        clearFilterSelections({ commit }) {
            commit('clearSelectedBrands');
            commit('clearSelectedApplications');

        },
        setCurrentFiltered({ commit }, payload) {
            commit('setCurrentFiltered', payload);
            localStorage.setItem('currentFiltered', JSON.stringify(payload));
        },
        clearCart({ commit }) {
            commit('updateCart', []);
        }
    },
};
