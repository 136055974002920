<template>
    <nav id="mobile-nav">
        <ul id="nav-list">
            <li>
                <router-link
                    :to="{ path: '/' }"
                    class="mm-listitem__text r-link"
                    >Home</router-link
                >
            </li>
            <li v-if="$store.state.useProducts">
                <span>Products</span>
                <ul>
                    <li>
                        <span>Eye Protection</span>
                        <ul>
                            <li>
                                <!-- <span>{{ productCount(3) }}</span> -->
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'eye-protection',
                                            subcat: 'full-frame'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Full Frame</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'eye-protection',
                                            subcat: 'goggles'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Goggles</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'eye-protection',
                                            subcat: 'over-the-glass'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Over-The-Glass</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'eye-protection',
                                            subcat: 'rimless-frame'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Rimless Frame</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'eye-protection',
                                            subcat: 'semi-rimless-frame'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Semi-Rimless Frame</router-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>Fall Protection</span>
                        <ul>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'fall-protection',
                                            subcat: 'anchor'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Anchor</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'fall-protection',
                                            subcat: 'body'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Body</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'fall-protection',
                                            subcat: 'connector'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Connector</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'fall-protection',
                                            subcat: 'kits'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Kits</router-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <router-link
                            :to="{
                                name: 'products',
                                query: {
                                    cat: 'first-aid-kits'
                                }
                            }"
                            class="mm-listitem__text r-link"
                            >First Aid Kits</router-link
                        >
                    </li>
                    <li>
                        <span>Hand Protection</span>
                        <ul>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'hand-protection',
                                            subcat: 'disposable'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Disposable</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'hand-protection',
                                            subcat: 'latex-reusable'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Latex Reusable</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'hand-protection',
                                            subcat: 'nitrile-reusable'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Nitrile Reusable</router-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>Head &amp; Face Protection</span>
                        <ul>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'head-face-protection',
                                            subcat: 'hard-hat-accessories'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Hard Hat Accessories</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'head-face-protection',
                                            subcat: 'safety-works-hard-hats'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Safety Works Hard Hats</router-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>Hearing Protection</span>
                        <ul>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'hearing-protection',
                                            subcat: 'ear-muffs'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Ear Muffs</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'hearing-protection',
                                            subcat: 'multiple-use-ear-plugs'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Multiple Use Ear Plugs</router-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>Protective Clothing</span>
                        <ul>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'protective-clothing',
                                            subcat: 'coveralls'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Coveralls</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'protective-clothing',
                                            subcat: 'safety-vests'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Safety Vests</router-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>Respiratory Protection</span>
                        <ul>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'respiratory-protection',
                                            subcat:
                                                'disposable-respirators-and-masks'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Disposable Respirators and
                                    Masks</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'respiratory-protection',
                                            subcat: 'full-facepiece-respirator'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Full Facepiece Respirator</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'respiratory-protection',
                                            subcat: 'half-mask-respirator'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Half-Mask Respirator</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: 'respiratory-protection',
                                            subcat:
                                                'replacement-cartridges-and-filters'
                                        }
                                    }"
                                    class="mm-listitem__text r-link"
                                    >Replacement Cartridges and
                                    Filters</router-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <router-link
                            :to="{
                                name: 'products',
                                query: {
                                    cat: 'traffic-cones'
                                }
                            }"
                            class="mm-listitem__text r-link"
                            >Traffic Cones</router-link
                        >
                    </li>
                </ul>
            </li>

            <li>
                <router-link
                    :to="{ path: '/literature' }"
                    class="mm-listitem__text r-link"
                    >Literature</router-link
                >
            </li>
            <li>
                <router-link
                    :to="{ path: '/technical-support' }"
                    class="mm-listitem__text r-link"
                    >Technical Support</router-link
                >
            </li>
            <li>
                <router-link
                    :to="{ path: '/contact' }"
                    class="mm-listitem__text r-link"
                    >Contact Us</router-link
                >
            </li>
            <li>
                <a class="mm-listitem__text r-link" href="https://www.amazon.com/stores/page/780E17B6-0AF6-4F1A-9F00-07D537F3A30B?ingress=3" target="_blank"
                    >BUY NOW</a>
            </li>
            <!-- <li v-if="isLoggedIn">
                <router-link
                    :to="{ name: 'dashboard' }"
                    class="mm-listitem__text r-link"
                    >User Dashboard</router-link
                >
            </li>
            <li v-if="isLoggedIn">
                <router-link
                    :to="{ name: 'myAccount' }"
                    class="mm-listitem__text r-link"
                    >My Account</router-link
                >
            </li>
            <li v-if="isLoggedIn">
                <router-link
                    :to="{ name: 'changePassword' }"
                    class="mm-listitem__text r-link"
                    >Change Password</router-link
                >
            </li>
            <li v-if="isLoggedIn && user.role == 'admin'">
                <router-link
                    :to="{ name: 'userList' }"
                    class="mm-listitem__text r-link"
                    >Users List</router-link
                >
            </li>
            <li v-if="isLoggedIn && user.approve == 1">
                <router-link
                    :to="{ name: 'myTools' }"
                    class="mm-listitem__text r-link"
                    >My Tools</router-link
                >
            </li> -->

            <!-- <li v-if="isLoggedIn">
                <a
                    href="javascript:void(0);"
                    @click.prevent="logout"
                    class="mm-listitem__text r-link"
                    >Log Out</a
                >
            </li>

            <li v-else>
                <router-link
                    :to="{ path: '/auth/login' }"
                    class="mm-listitem__text r-link"
                    >Distributor Login</router-link
                >
            </li> -->
            <li>
                <router-link
                    :to="{ name: 'privacy' }"
                    class="mm-listitem__text r-link"
                    >Privacy Policy</router-link
                >
            </li>
            <li>
                <a
                    href="https://pipglobal.com/career"
                    target="_blank"
                    class="mm-listitem__text r-link"
                    >Career Opportunities</a
                >
            </li>
        </ul>
    </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { logIn, logOut } from "../utils/auth";
export default {
    data() {
        return {};
    },
    computed: {
        ...mapState({
            products: state => state.products,
            isLoggedIn: state => state.isLoggedIn,
            user: state => state.user
        })
    },
    methods: {
        closeMenu() {
            api.close();
        },
        productCount(category) {
            return this.products.length;
        },
        async logout() {
            try {
                axios.post("/logout");
                this.$store.dispatch("logout");
                this.$router.push({ name: "home" });
            } catch (error) {
                this.$store.dispatch("logout");
            }
        }
    },
    created() {
        document.addEventListener("DOMContentLoaded", () => {
            const menu = new Mmenu("#mobile-nav", {
                extensions: ["theme-dark"],
                counters: false,
                navbars: [
                    {
                        position: "top",
                        content: ["prev", "title"]
                    }
                    // {
                    //     position: "bottom",
                    //     content: [
                    //         "<a class='fab fa-facebook fa-2x' href='https://www.facebook.com/westchestergear/' target='blank'></a>",
                    //         "<a class='fab fa-twitter-square fa-2x' href='https://www.twitter.com/westchestergear' target='blank'></a>",
                    //         "<a class='fab fa-instagram-square fa-2x' href='https://www.instagram.com/westchestergear/' target='blank'></a>",
                    //         "<a class='fab fa-youtube-square fa-2x' href='https://www.youtube.com/westchestergloves' target='blank'></a>",
                    //         "<a class='fab fa-linkedin fa-2x' href='https://www.linkedin.com/company/west-chester-holdings' target='blank'></a>"
                    //     ]
                    // }
                ]
            });
            const api = menu.API;

            $(".r-link").click(function() {
                api.close();
            });
        });
    },
    mounted() {
        console.log("count: ", this.productCount(4));
    }
};
</script>

<style lang="scss" scoped>
.modal.fade .modal-dialog {
    position: fixed;
    margin: auto;
    width: 500px;
    height: 100%;
    left: -500px;
    transition: opacity 0.4s linear, left 0.4s ease-out;
}

.modal.fade.show .modal-dialog {
    left: 0;
}

.modal .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal .modal-body {
    padding: 15px 15px 80px;
}

/* ----- MODAL STYLE ----- */
.modal-content {
    border-radius: 0;
    border: none;
}

.modal-header {
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
}

button {
    &:hover {
        background: transparent;
    }
}

.router-link-active {
    border-bottom: 1px solid #222;
}
</style>
