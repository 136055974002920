require('./bootstrap');

import router from './routes';
import VueRouter from 'vue-router';
import Vue from 'vue';
import i18n from "./i18n";
var VueScrollTo = require('vue-scrollto');

import BootstrapVue from 'bootstrap-vue';
import JwPagination from 'jw-vue-pagination';
import Vuex, { mapState } from 'vuex';
import storeDefinition from "./store";
import Index from './Index';
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie';

import DataTable from "@andresouzaabreu/vue-data-table";
Vue.component("data-table", DataTable);

import "@andresouzaabreu/vue-data-table/dist/DataTable.css";

window.Vue = require('vue').default;
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueScrollTo, {
    container: "body",
     duration: 500,
     easing: "ease",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
});


Vue.use(BootstrapVue);
Vue.config.productionTip = false;

const store = new Vuex.Store(storeDefinition,{

    plugins: [
        createPersistedState({
          getState: (key) => Cookies.getJSON(key),
          setState: (key, state) => Cookies.set(key, state, { expires: 3, secure: true })
        })
      ]
});
//Global component registrations
Vue.component('main-nav', require('./components/nav/MainNav.vue').default);
Vue.component('mobile-nav', require('./components/nav/MobileNav.vue').default);
Vue.component('local-switcher', require('./components/nav/LocaleSwitcher.vue').default);
Vue.component('home', require('./components/pages/Home.vue').default);
Vue.component('main-footer', require('./components/Footer.vue').default);
Vue.component('hero-slider', require('./components/HeroSlider.vue').default);
Vue.component('slide', require('./components/shared/Slide.vue').default);
Vue.component('category', require('./components/pages/Category.vue').default);
Vue.component('product-list', require('./components/product/ProductList.vue').default);
Vue.component('product-list-item', require('./components/product/ProductListItem.vue').default);
Vue.component('smart-filters', require('./components/filters/SmartFilters.vue').default);
Vue.component('smart-filter', require('./components/filters/SmartFilter.vue').default);
Vue.component('mobile-filters', require('./components/filters/MobileFilters.vue').default);
Vue.component('product', require('./components/product/Product.vue').default);
Vue.component('jw-pagination', JwPagination);
Vue.component('contact', require('./components/pages/Contact.vue').default);
Vue.component('v-errors', require('./components/shared/ValidationErrors.vue').default);
Vue.component('success', require('./components/shared/Success.vue').default);
Vue.component('fatal-error', require('./components/shared/FatalError.vue').default);
Vue.component('modal', require('./components/shared/Modal.vue').default);
Vue.component('search', require('./components/filters/Search.vue').default);
Vue.component('back-to-top', require('./components/shared/BackToTop.vue').default);
Vue.component('privacy', require('./components/pages/PrivacyPolicy.vue').default);





// interceptor is like a plugin,
window.axios.interceptors.response.use(
    // it is always called when you get a response for anything, like a global thing
    response=>{
        return response;
    },
    error=>{
        if(401==error.response.status){
            store.dispatch("logout");
        }

        // line to add to continue working
        return Promise.reject(error);
    }
);


const app = new Vue({
    el: '#app',
    router,
    store,
    i18n,
    components: {
        'index': Index,
    },
    created() {
        this.$store.dispatch('loadProducts');
        this.$store.dispatch('loadCategories');
        this.$store.dispatch('loadLiterature');
        this.$store.dispatch('loadStoredState');
    },
    beforeCreate() {
        this.$store.dispatch('loadStoredProduct');
    }

});
