<template>
    <div
        class="modal fade"
        id="searchPanel"
        tabindex="-1"
        role="dialog"
        aria-label="Search Panel"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header bg-dark" style="border-radius: 0;">
                    <div class="input-group">
                        <input
                            type="text"
                            v-model="searchValue"
                            class="form-control"
                            placeholder="Search Products"
                            id="search-input"
                            aria-label="Search Input"
                        />
                    </div>

                    <button
                        type="button"
                        class="btn btn-light close text-white"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex flex-column p-2  w-100">
                        <div
                            v-for="(product, index) in filteredProducts.slice(
                                0,
                                6
                            )"
                            :key="'product' + index"
                            class="d-flex mb-2"
                        >
                            <router-link
                                :to="{
                                    name: 'product',
                                    params: { id: product.id }
                                }"
                                @click.native="setProduct(product.id)"
                                class="d-flex"
                            >
                                <b-img
                                    v-for="(image,
                                    index) in product.images.slice(0, 1)"
                                    :key="index"
                                    :src="`/images/products/${image.path}`"
                                ></b-img>

                                <div class="ml-3 p-3">
                                    <p class="text-secondary font-weight-bold">
                                        {{ product.prod_id }}
                                    </p>
                                    <p
                                        class="desc"
                                        v-html="product.short_desc"
                                    ></p>
                                </div>
                            </router-link>
                        </div>
                        <div
                            class="info d-flex flex-wrap bg-secondary text-light justify-content-around"
                            v-if="filteredProducts.length"
                        >
                            <div class="p-3">
                                SHOWING {{ filteredLength }} OF
                                {{ filteredProducts.length }}
                            </div>
                            <div class="py-2">
                                <b-button
                                    class="mt-1 btn btn-outline-light btn-sm"
                                    v-if="filteredProducts.length > 6"
                                    :to="{
                                        name: 'products',
                                        query: { s: searchValue }
                                    }"
                                    @click.native="closeSearchPanel"
                                >
                                    ALL RESULTS
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            searchValue: ""
        };
    },
    computed: {
        filteredProducts() {
            let filtered = [];
            //process search criteria
            if (this.searchValue != "" && this.searchValue) {
                filtered = this.products.filter(product => {
                    return (
                        product.prod_id.includes(
                            this.searchValue.toUpperCase().trim()
                        ) ||
                        // product.skus.includes(this.searchValue.trim()) ||
                        product.short_desc
                            .toLowerCase()
                            .includes(this.searchValue.toLowerCase().trim())
                    );
                });
            }
            return filtered;
        },
        filteredLength() {
            return this.filteredProducts.length > 6
                ? 6
                : this.filteredProducts.length;
        },
        ...mapState(["products"])
    },
    methods: {
        setProduct(id) {
            this.$store.dispatch("setProduct", id);
            $("#searchPanel").modal("hide");
            $(".modal-backdrop").css("display", "none");
        },
        clearInput() {
            $("#search-input").val("");
            this.searchValue = "";
        },
        closeSearchPanel() {
            $("#searchPanel").modal("hide");
        }
    }
};
</script>

<style lang="scss" scoped>
.modal.fade .modal-dialog {
    position: fixed;
    margin: auto;
    width: 350px;
    max-width: 80%;
    height: 100%;
    right: -350px;
    transition: opacity 0.4s linear, right 0.4s ease-out;
}

.modal.fade.show .modal-dialog {
    right: 0;
}

.modal-backdrop {
    z-index: 1040 !important;
}

.modal .modal-content {
    height: 100%;
    overflow-y: auto;
    z-index: 1100 !important;
}

.modal .modal-body {
    padding: 15px 15px 80px;
    position: relative;
}

/* ----- MODAL STYLE ----- */
.modal-content {
    border-radius: 0;
    border: none;
}

.modal-header {
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
}
img {
    max-width: 40%;
    object-fit: contain;
}
p {
    margin: 0;
}

#search-input {
    position: relative;
    border-radius: 0;
}

#searchClear {
    height: 14px;
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
    margin: auto;
    font-size: 1rem;
    cursor: pointer;
    color: #ccc;
}
.info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 0.8rem;
}
.btn.close {
    span {
        font-size: 1.4rem;
    }

    &:hover {
        background: transparent;
    }
}
.desc {
    color: #333;
    line-height: 1.1;
}
</style>
