<template>
    <div>
        <a href="#" id="scrollToTop" v-scroll-to="'#pipheader'">
            <i class="fas fa-arrow-up text-light"></i>
        </a>
    </div>
</template>

<script>
export default {
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll(event) {
            this.isUserScrolling = window.scrollY > 200;
            if (this.isUserScrolling) {
                $("#scrollToTop").css("display", "flex");
            } else $("#scrollToTop").css("display", "none");
        }
    }
};
</script>

<style lang="scss" scoped></style>
