<template>
    <div>
        <div
            class="row no-gutters"
            id="category-header"
            :style="
                `background-image: linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.2)),url('${bgImage}');`
            "
        >
            <div class="hero-text">
                <h1 class="text-light">
                    {{ title }}
                </h1>
            </div>
        </div>
        <div id="main-container" class="position-relative d-flex">
            <!-- <div>
                <i
                    id="filters-button"
                    class="fas fa-filter position-absolute w-100"
                    data-toggle="collapse"
                    data-target="#mobile-filters"
                ></i>
            </div>

            <mobile-filters class="d-xl-none d-lg-block"></mobile-filters> -->

            <div id="sidebar" class="pt-3">
                <smart-filters></smart-filters>
            </div>
            <div class="p-3 mw-100 w-100 mx-auto">
                <product-list></product-list>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        bgImage() {
            const $path = "/images/headers/";
            if (this.$route.query.cat) {
                switch (this.$route.query.cat) {
                    case "hearing-protection":
                        return $path + "hearing-Divider.jpg";
                        break;
                    case "eye-protection":
                        return $path + "Eyewear-Divider.jpg";
                    case "fall-protection":
                        return $path + "Fall-Protection-Divide2r-1400x525.jpg";
                    case "first-aid-kits":
                        return $path + "First-Aid-Divider-1-1400x525.jpg";
                    case "hand-protection":
                        return $path + "Hand-Divider-1400x525.jpg";
                    case "head-face-protection":
                        return $path + "Head-Divider-1-1400x525.jpg";
                    case "respiratory-protection":
                        return $path + "Respiratory-Divider2-1400x525.jpg";
                    case "protective-clothing":
                        return $path + "Vest-Divider-1400x525.jpg";
                    case "traffic-cones":
                        return $path + "cones-Divider-1400x525.jpg";
                    default:
                        return "https://via.placeholder.com/2000x500/111";
                }
            }
            return null;
        },
        title() {
            if (this.$route.query.cat) {
                switch (this.$route.query.cat) {
                    case "hearing-protection":
                        return "Hearing Protection";
                        break;
                    case "eye-protection":
                        return "Eye Protection";
                        break;
                    case "fall-protection":
                        return "Fall Protection";
                        break;
                    case "first-aid-kits":
                        return "First Aid Kits";
                        break;
                    case "hand-protection":
                        return "Hand Protection";
                        break;
                    case "head-face-protection":
                        return "Head & Face Protection";
                        break;
                    case "respiratory-protection":
                        return "Respiratory Protection";
                        break;
                    case "protective-clothing":
                        return "Protective Clothing";
                        break;
                    case "traffic-cones":
                        return "Traffic Cones";
                        break;
                    default:
                        return "Products";
                }
            } else if (!this.$route.query.cat) {
                return "Products";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
#filters-button {
    transition: all 0.4s ease-in-out;
    color: whitesmoke;
    top: 10px;
    left: 20px;
    &:hover {
        cursor: pointer;
    }
}
.mobile-filters-container {
    border-top: 1px solid white;
    h5 {
        font-family: "Acumin Pro Condensed", Helvetica, Arial, sans-serif;
        font-size: 0.9rem;
        text-transform: uppercase;
    }
}

.filters-table {
    td {
        font-size: 0.8rem;
    }
}
label {
    &:hover {
        cursor: pointer;
    }
}
#category-header {
    height: 30vh;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-color: #111;
}
.hero-text {
    width: 50%;
    position: absolute;
    bottom: 3rem;
    left: 1.3rem;
    #header-text {
        font-size: 0.9rem;
        width: 80%;
    }
    h1 {
        font-size: 3.5rem;
        font-family: "Oswald", sans-serif;
        @media screen and (max-width: 400px) {
            font-size: 3rem;
        }
    }
}
#sidebar {
    background: whitesmoke;
    min-width: 270px;
    max-width: 270px;
    color: green;
    @media screen and (max-width: 1182px) {
        display: none !important;
    }
}
</style>
