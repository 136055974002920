var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"searchPanel","tabindex":"-1","role":"dialog","aria-label":"Search Panel"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-dark",staticStyle:{"border-radius":"0"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search Products","id":"search-input","aria-label":"Search Input"},domProps:{"value":(_vm.searchValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchValue=$event.target.value}}})]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"d-flex flex-column p-2  w-100"},[_vm._l((_vm.filteredProducts.slice(
                            0,
                            6
                        )),function(product,index){return _c('div',{key:'product' + index,staticClass:"d-flex mb-2"},[_c('router-link',{staticClass:"d-flex",attrs:{"to":{
                                name: 'product',
                                params: { id: product.id }
                            }},nativeOn:{"click":function($event){return _vm.setProduct(product.id)}}},[_vm._l((product.images.slice(0, 1)),function(image,index){return _c('b-img',{key:index,attrs:{"src":("/images/products/" + (image.path))}})}),_vm._v(" "),_c('div',{staticClass:"ml-3 p-3"},[_c('p',{staticClass:"text-secondary font-weight-bold"},[_vm._v("\n                                    "+_vm._s(product.prod_id)+"\n                                ")]),_vm._v(" "),_c('p',{staticClass:"desc",domProps:{"innerHTML":_vm._s(product.short_desc)}})])],2)],1)}),_vm._v(" "),(_vm.filteredProducts.length)?_c('div',{staticClass:"info d-flex flex-wrap bg-secondary text-light justify-content-around"},[_c('div',{staticClass:"p-3"},[_vm._v("\n                            SHOWING "+_vm._s(_vm.filteredLength)+" OF\n                            "+_vm._s(_vm.filteredProducts.length)+"\n                        ")]),_vm._v(" "),_c('div',{staticClass:"py-2"},[(_vm.filteredProducts.length > 6)?_c('b-button',{staticClass:"mt-1 btn btn-outline-light btn-sm",attrs:{"to":{
                                    name: 'products',
                                    query: { s: _vm.searchValue }
                                }},nativeOn:{"click":function($event){return _vm.closeSearchPanel($event)}}},[_vm._v("\n                                ALL RESULTS\n                            ")]):_vm._e()],1)]):_vm._e()],2)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-light close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])}]

export { render, staticRenderFns }