<template>
    <div>
        <div
            class="row no-gutters"
            style="background-image: url('https://via.placeholder.com/2000x250/333333'); border-bottom: 2px solid white; transform: translateY(-0px);"
            id="hero"
        >
            <div class="hero-text">
                <h2 class="text-light text-uppercase">
                    A Retail Protective Gear Powerhouse
                </h2>
                <p class="text-light">
                    West Chester Protective Gear is a leader in merchandising
                    solutions. We are a global solution provider offering vast
                    experience partnered with continuing innovation and
                    cutting-edge capabilities. We're here to help you sell more
                    with our specialty services in private label solutions,
                    e-commerce support, and custom retail solutions .
                </p>
                <a
                    href="#"
                    class="btn btn-outline-light text-uppercase"
                    v-scroll-to="'#read-more'"
                    >Learn More</a
                >
            </div>
        </div>
        <div id="read-more" class="row no-gutters">
            <div
                id="private-label-container"
                class="col-md-4 text-light d-flex flex-column justify-content-center"
            >
                <div class="text-container p-5">
                    <h3>PRIVATE LABEL SOLUTIONS</h3>
                    <p>
                        Leading distributors and manufacturers depend on West
                        Chester private label programs to extend their offerings
                        and add value to their customers.
                    </p>
                    <a
                        href="#"
                        class="btn btn-outline-light text-uppercase w-50 mx-auto"
                        v-scroll-to="'#private-label'"
                        >learn more</a
                    >
                </div>
            </div>
            <div
                id="ecommerce-support-container"
                class="col-md-4 text-light d-flex flex-column justify-content-center"
            >
                <div class="text-container p-5">
                    <h3>E-COMMERCE SUPPORT</h3>
                    <p>
                        Our graphic specialists create stunning product shots
                        from every angle imaginable, making products stick out
                        instead of just blending in.
                    </p>
                    <a
                        href="#"
                        class="btn btn-outline-light text-uppercase w-50 mx-auto"
                        v-scroll-to="'#ecommerce-support'"
                        >learn more</a
                    >
                </div>
            </div>
            <div
                id="custom-retail-container"
                class="col-md-4 text-light d-flex flex-column justify-content-center"
            >
                <div class="text-container p-5">
                    <h3>CUSTOM RETAIL SOLUTIONS</h3>
                    <p>
                        Our team has decades of experience creating custom
                        designed solutions for every retailer, big and small.
                    </p>
                    <a
                        href="#"
                        class="btn btn-outline-light text-uppercase w-50 mx-auto"
                        v-scroll-to="'#custom-retail'"
                        >learn more</a
                    >
                </div>
            </div>
        </div>
        <div>
            <div id="private-label" class="row no-gutters info-tile p-5">
                <div class="info-content">
                    <h2>Private Label Solutions</h2>
                    <p>
                        Leading distributors and manufacturers depend on West
                        Chester private label programs to extend their offerings
                        and add value to their customers.
                    </p>
                    <p>
                        Our expertise and capabilities in product development,
                        procurement, logistics, and technology make line
                        extensions feasible and cost-effective for our
                        customers.
                    </p>
                    <p>
                        West Chester’s private label program gives customers
                        significant advantages:
                    </p>
                    <ul>
                        <li>
                            Doing business with an experienced and trusted
                            global supplier
                        </li>
                        <li>Low minimum order quantities</li>
                        <li>Fast turn times</li>
                        <li>An affordable way to expand your product line</li>
                    </ul>
                    <p>
                        West Chester’s operating philosophy is built on service,
                        quality, innovation, and value. We grow our business by
                        growing yours. Contact us today to learn how a private
                        label program can drive revenues and provide a
                        competitive advantage.
                    </p>
                    <router-link
                        :to="{ name: 'contact' }"
                        class="btn btn-secondary btn-block text-uppercase"
                        >Contact Us About Private Label Solutions</router-link
                    >
                </div>
            </div>
        </div>
        <hr />
        <div>
            <div id="ecommerce-support" class="row no-gutters info-tile p-5">
                <div class="info-content">
                    <h2>E-Commerce Support</h2>
                    <p>
                        West Chester Protective Gear has lead the charge in the
                        evolution of e-commerce. We work with our clients to
                        create beautiful customer experiences across a broad
                        array of online go to market solutions.
                    </p>
                    <p>
                        Our team specializes in product photography. Using our
                        cutting edge, in-house photography lab we are able to go
                        above and beyond what your competitors are doing. With
                        360 degree photos and interactive media, we are able to
                        guide the customer through the purchase funnel. We help
                        them every step of the way until they are ready to
                        checkout.
                    </p>
                    <router-link
                        :to="{ name: 'contact' }"
                        class="btn btn-secondary btn-block text-uppercase"
                        >Contact Us About E-Commerce Support</router-link
                    >
                </div>
            </div>
        </div>
        <hr />
        <div>
            <div id="custom-retail" class="row no-gutters info-tile p-5">
                <div class="info-content">
                    <h2>Custom Retail Solutions</h2>
                    <p>
                        West Chester Protective Gear is a global solution
                        provider with a rich heritage in sourcing and logistics.
                        We receive thousands of inbound containers every year
                        with hundreds of direct imports for customers.
                    </p>
                    <p>
                        West Chester partners with manufacturers in 12 countries
                        to deliver the best products to the market with on-time
                        delivery and consistent quality. We also have a
                        Representative Office in Shenzhen, China.
                    </p>
                    <p>
                        Why do Industrial customers rely on West Chester’s
                        global supply chain solutions?
                    </p>
                    <ul>
                        <li>
                            Managed supply chain delivery risk with primary,
                            secondary, and third-tier factories
                        </li>
                        <li>
                            Official presence in China with West Chester
                            Shenzhen Representative Office
                        </li>
                        <li>
                            Direct import capabilities-from the dock to your
                            door
                        </li>
                        <li>
                            VIP status with steam lines and freight forwarders
                        </li>
                        <li>
                            Sophisticated sales and operational planning
                            processes
                        </li>
                        <li>C-TPAT Tier II status</li>
                    </ul>
                    <router-link
                        :to="{ name: 'contact' }"
                        class="btn btn-secondary btn-block text-uppercase"
                        >Contact Us About Custom Retail Solutions</router-link
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
#hero {
    filter: grayscale(1);
    .hero-text {
        width: 100%;
        height: 100%;
        padding: 5rem;
        p {
            width: 40%;
            @media screen and (max-width: 990px) {
                width: 100%;
            }
        }
    }
}

.col-md-4 {
    min-height: 60vh;
    position: relative;
    background-size: cover;
    &:hover {
        .text-container {
            background: rgba(0, 0, 0, 0.8);
        }
    }
}

#private-label-container,
#ecommerce-support-container,
#custom-retail-container {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        filter: brightness(0.6);
    }
}

#private-label-container {
    &:before {
        background-image: url("/images/private-label-hero-bg.jpg");
    }
}

#ecommerce-support-container {
    &:before {
        background-image: url("/images/ecommerce-support-hero-bg.jpg");
    }
}

#custom-retail-container {
    &:before {
        background-image: url("/images/retail-custom-hero-bg.jpg");
    }
}
.text-container {
    transition: all 0.4s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    z-index: 10;
}
.info-tile {
    color: #333;
    font-family: "acumin-pro", Helvetica, Arial, sans-serif;
    h2 {
        font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
        font-weight: 700;
        text-align: center;
        border-bottom: 1px solid #333;
    }
    .info-content {
        margin: 0 auto;
        width: 45%;
        ul {
            margin-left: 2rem;
            margin-bottom: 2rem;
        }
        @media screen and (max-width: 1146px) {
            width: 80%;
        }
    }
}
</style>
