<template>
    <b-carousel-slide :caption="caption" :text="text" :img-src="imgSrc">
        <slot></slot
    ></b-carousel-slide>
</template>

<script>
export default {
    props: {
        caption: String,
        text: String,
        imgSrc: String
    }
};
</script>

<style scoped></style>
