<template>
    <div>
        <div class="px-4">
            <div class="filters h-100">
                <h5 class="text-uppercase text-primary">
                    Categories
                </h5>
                <ul class="list-style-none">
                    <li
                        v-for="(category, index) in categories"
                        :key="'cat' + index"
                        :class="{ active: isSelected(category.slug) }"
                    >
                        <router-link
                            :to="{
                                name: 'products',
                                query: { cat: category.slug }
                            }"
                            @click.native="clearFilters(category.slug)"
                            >{{ category.name }}</router-link
                        >
                        <ul
                            v-if="
                                isSelected(category.slug) &&
                                    category.children.length
                            "
                            class="subcategories"
                        >
                            <li
                                v-for="(subcategory,
                                index) in category.children"
                                :key="'sub' + index"
                                :class="{
                                    active: subSelected(subcategory.slug)
                                }"
                            >
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            cat: category.slug,
                                            subcat: subcategory.slug
                                        }
                                    }"
                                    @click.native="clearFilters"
                                >
                                    {{ subcategory.name }}
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
                <!-- <smart-filter name="Brand">
                    <table>
                        <tbody>
                            <tr
                                v-for="(brand, index) in brands"
                                :key="'brand' + index"
                            >
                                <td
                                    :class="{
                                        active: brandActive(brand)
                                    }"
                                    v-if="brandInFiltered(brand)"
                                >
                                    <i
                                        class="fas fa-times-circle"
                                        v-if="brandActive(brand)"
                                        @click.prevent="selectBrand(brand)"
                                    ></i>
                                    <input
                                        type="checkbox"
                                        :value="brand"
                                        @change="selectBrand(brand)"
                                        v-else
                                    />
                                    <label
                                        for=""
                                        @click.prevent="selectBrand(brand)"
                                        >{{ brand }}</label
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </smart-filter> -->
                <!-- <smart-filter name="Application">
                    <table>
                        <tr
                            v-for="(application, index) in applications"
                            :key="'app' + index"
                        >
                            <td
                                :class="{
                                    active: applicationActive(application)
                                }"
                                v-if="applicationInFiltered(application)"
                            >
                                <i
                                    class="fas fa-times-circle"
                                    v-if="applicationActive(application)"
                                    @click.prevent="
                                        selectApplication(application)
                                    "
                                ></i>
                                <input
                                    type="checkbox"
                                    id="application.id"
                                    :value="application"
                                    @change="selectApplication(application)"
                                    v-else
                                /><label
                                    :for="applications"
                                    class="ml-2"
                                    @click.prevent="
                                        selectApplication(application)
                                    "
                                    >{{ application }}</label
                                >
                            </td>
                        </tr>
                    </table>
                </smart-filter> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {};
    },
    computed: {
        ...mapState([
            "categories",
            "brands",
            "applications",
            "selectedBrands",
            "selectedApplications",
            "categoryFilteredProducts"
        ])
    },
    methods: {
        isSelected(cat) {
            return cat == this.$route.query.cat;
        },
        subSelected(subcat) {
            return subcat == this.$route.query.subcat;
        },
        applicationActive(app) {
            return this.selectedApplications
                ? this.selectedApplications.includes(app)
                : false;
        },
        selectBrand(brand) {
            this.$store.dispatch("setSelectedBrands", brand);
        },
        brandActive(brand) {
            return this.$route.query.brands
                ? this.$route.query.brands.split(",").includes(brand)
                : false;
        },
        selectApplication(app) {
            this.$store.dispatch("setSelectedApplications", app);
        },
        clearFilters() {
            this.$store.dispatch("clearFilterSelections");
        },
        brandInFiltered(brand) {
            if (this.categoryFilteredProducts.length > 1) {
                return this.categoryFilteredProducts.some(
                    product => product.brand == brand
                );
            } else return true;
        },
        applicationInFiltered(app) {
            if (this.categoryFilteredProducts.length > 1) {
                return this.categoryFilteredProducts.some(product =>
                    product.applications.includes(app)
                );
            } else return true;
        }
    },
    created() {},
    watch: {
        selectedBrands: function(newValue, prevValue) {
            let brands = this.selectedBrands.join();
            var category, subcategory, applications;
            category = this.$route.query.cat ?? null;
            subcategory = this.$route.query.subcat ?? null;
            applications = this.$route.query.applications ?? null;
            if (category && subcategory && applications) {
                this.$router
                    .push({
                        query: {
                            cat: category,
                            subcat: subcategory,
                            applications: applications,
                            brands: brands
                        }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (category && subcategory && !applications) {
                this.$router
                    .push({
                        query: {
                            cat: category,
                            subcat: subcategory,
                            brands: brands
                        }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (category && !subcategory && !applications) {
                this.$router
                    .push({
                        query: { cat: category, brands: brands }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (category && !subcategory && applications) {
                this.$router
                    .push({
                        query: {
                            cat: category,
                            brands: brands,
                            applications: applications
                        }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (!category && !subcategory && applications) {
                this.$router
                    .push({
                        query: { applications: applications, brands: brands }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (!category && !subcategory && !applications) {
                this.$router
                    .push({
                        query: { brands: brands }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            }
            if (this.selectedBrands.length < 1) {
                let query = Object.assign({}, this.$route.query);
                delete query.brands;
                this.$router.replace({ query });
            }
        },
        selectedApplications: function(newValue, prevValue) {
            let applications = this.selectedApplications.join();
            var category, subcategory, brands;
            category = this.$route.query.cat ?? null;
            subcategory = this.$route.query.subcat ?? null;
            brands = this.$route.query.brands ?? null;
            if (category && subcategory && brands) {
                this.$router
                    .push({
                        query: {
                            cat: category,
                            subcat: subcategory,
                            applications: applications,
                            brands: brands
                        }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (category && subcategory && !brands) {
                this.$router
                    .push({
                        query: {
                            cat: category,
                            subcat: subcategory,
                            applications: applications
                        }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (category && !subcategory && !brands) {
                this.$router
                    .push({
                        query: { cat: category, applications: applications }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (!category && !subcategory && brands) {
                this.$router
                    .push({
                        query: { applications: applications, brands: brands }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            } else if (!category && !subcategory && !brands) {
                this.$router
                    .push({
                        query: { applications: applications }
                    })
                    .catch(err => {
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            return;
                        }
                    });
            }
            if (this.selectedApplications.length < 1) {
                let query = Object.assign({}, this.$route.query);
                delete query.applications;
                this.$router.replace({ query });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
td {
    font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
}
ul {
    list-style: none;
    font-size: 1em;
    font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
}
.filters {
    background: whitesmoke;
}
a {
    color: #333;
    font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
    &:hover {
        color: #218080;
    }
}

h5 {
    font-size: 1.2rem;
    font-weight: 700;
    border-bottom: 1px solid #333;
    font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
}

ul.subcategories {
    // background: white;
    color: #333;
    font-size: 1rem;
    // margin: 0.5em 0 0.5em 1em;
    // padding: 0.5em;
    font-weight: 400;
    a {
        color: #333;
        &:hover {
            color: #218080;
            font-size: 1rem;
        }
        &::before {
            content: "///";
            color: #333;
            margin-right: 0.5rem;
        }
    }
}
.active {
    font-weight: 700;
    color: #333;
}
.brand-link {
    cursor: pointer;
    color: #333;
}

ul.subchannels {
    background: white;
    color: #333;
    font-size: 0.8rem;
    margin: 0.5em 0 0.5em 1em;
    padding: 0.5em;
}
i.fas.fa-times-circle,
label {
    &:hover {
        cursor: pointer;
    }
}

label {
    font-size: 1rem;
    font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
    margin: 0;
    margin-left: 0.5rem;
}
input[type="checkbox"] {
    &:hover {
        cursor: pointer;
    }
}
</style>
