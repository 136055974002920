<template>
    <div class="login-container">
        <div class="row">
            <div class="col-lg-2 sidebar">
                <h1 class="font-weight-normal pb-3">Login or Sign Up</h1>
                <div class="lines h-100 d-sm-none d-none d-lg-block"></div>
            </div>
            <div class="col-lg-10">
                <transition name="fade" v-if="errorLogin">
                    <fatal-error>Could not login, your credentials do not match our records. Please try again. </fatal-error>
                </transition>
                <transition name="fade" v-if="verifyUser">
                    <success >
                        Your Email has been verified you can log in to the portal now.
                    </success>

                </transition>
                <fatal-error v-if="hasNotVerified">
                    You have not verified your email address.
                </fatal-error>
                <fatal-error v-if="hasArchive">
                    You no longer have access to the portal.
                </fatal-error>
                <fatal-error v-if="verifyInvalid">
                        Something went worng. Invalid code.
                    </fatal-error>
                <div class="modal fade"  id="userVerification" tabindex="-1" role="dialog" aria-labelledby="userVerificationLabel" aria-hidden="true" >
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                Please check you email for user verification. You can login to the portal once the email has been verified.
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-dismiss="modal" >Close</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div>
                    <h2>This area is restricted to Employees and Rep Agents only</h2>
                    <p>If you are a rep agent or employee of Westchester, we invite you to sign up for the My Tools area of our website, where you can access additional forms, sales documents and website tools. Any other person is not permitted access to this section, but can still use the main westchestergear.com website.</p>
                    <i>* Please sign up using your corporate email address in order to make the verification process faster.</i>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <div class="my-5">
                            <h3>LOGIN: <span>I HAVE A PASSWORD</span></h3>
                            <form>
                                <div class="form-group">
                                    <label for="email">Email Address:</label>
                                    <input type="text" name="email" class="form-control" v-model="email"
                                    :class="[{'is-invalid': errorFor('email')}]">
                                    <v-errors :errors="errorFor('email')"></v-errors>
                                </div>
                                <div class="form-group">
                                    <label for="password">Password:</label>
                                    <input type="password" name="password"  class="form-control" v-model="password"
                                    :class="[{'is-invalid': errorFor('password')}]">
                                    <v-errors :errors="errorFor('password')"></v-errors>
                                </div>
                                <button class="btn btn-primary" type="submit" :disabled="loading" @click.prevent="dashboardLogin">Login</button>
                                <hr />
                                <div class="text-nowrap">
                                    Forgotten password?
                                    <router-link :to="{path: '/reset-password'}" class="font-weight-bold">Reset password</router-link>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-5">
                        <div class="my-5">
                            <h3>SIGN UP: <span>I don't have a password</span></h3>
                            <div >
                                <form>
                                    <div class="form-group">
                                        <label for="name">* Name:</label>
                                        <input type="text" name="name"  class="form-control" v-model="user.name"
                                        :class="[{'is-invalid': errorFor('name')}]">
                                        <v-errors :errors="errorFor('name')"></v-errors>
                                    </div>


                                    <div class="form-group">
                                        <label for="company">* Company:</label>
                                        <input type="text" name="company"  class="form-control" v-model="user.company"
                                        :class="[{'is-invalid': errorFor('company')}]">
                                        <v-errors :errors="errorFor('company')"></v-errors>
                                    </div>
                                    <div class="form-group">
                                        <label for="title">* Title:</label>
                                        <input type="text" name="title" class="form-control" v-model="user.title"
                                        :class="[{'is-invalid': errorFor('title')}]">
                                        <v-errors :errors="errorFor('title')"></v-errors>
                                    </div>
                                    <div class="form-group">
                                        <label for="">* Address:</label>
                                        <input type="text" name="address" class="form-control" v-model="user.address"
                                        :class="[{'is-invalid': errorFor('address')}]">
                                        <v-errors :errors="errorFor('address')"></v-errors>
                                    </div>
                                    <div class="form-group">
                                        <label for="">Address Line 2:</label>
                                        <input type="text" name="address2"  class="form-control" v-model="user.address2"
                                        :class="[{'is-invalid': errorFor('address2')}]">
                                        <v-errors :errors="errorFor('address2')"></v-errors>
                                    </div>
                                    <div class="form-group">
                                        <label for="">* City:</label>
                                        <input type="text" name="city"  class="form-control" v-model="user.city"
                                        :class="[{'is-invalid': errorFor('city')}]">
                                        <v-errors :errors="errorFor('city')"></v-errors>
                                    </div>
                                    <div class="form-group">
                                        <label for="">* State:</label>
                                        <input type="text" name="state"  class="form-control" v-model="user.state"
                                        :class="[{'is-invalid': errorFor('state')}]">
                                        <v-errors :errors="errorFor('state')"></v-errors>
                                    </div>
                                    <div class="form-group">
                                        <label for="zipcode">* Zipcode:</label>
                                        <input type="text" name="zipcode"  class="form-control" v-model="user.zipcode"
                                        :class="[{'is-invalid': errorFor('zipcode')}]">
                                        <v-errors :errors="errorFor('zipcode')"></v-errors>
                                    </div>
                                    <div class="form-group">
                                        <label for="phone">* Phone Number:</label>
                                        <input type="text" name="phone" class="form-control" v-model="user.phone"
                                        :class="[{'is-invalid': errorFor('phone')}]">
                                        <v-errors :errors="errorFor('phone')"></v-errors>
                                    </div>
                                    <div class="form-group">
                                        <label for="email">* Email:</label>
                                        <input type="text" name="email" class="form-control" v-model="user.email"
                                        :class="[{'is-invalid': errorFor('email')}]" >
                                        <v-errors :errors="errorFor('email')"></v-errors>
                                    </div>
                                    <div class="form-group">
                                        <label for="password">* Password: <span>(MINIMUM OF 6 CHARACTERS)</span></label>
                                        <input type="password" name="password" class="form-control" v-model="user.password"
                                        :class="[{'is-invalid': errorFor('password')}]">
                                        <v-errors :errors="errorFor('password')"></v-errors>
                                    </div>
                                    <div class="form-group">
                                        <label for="password_confirmation">* Confirm Password:</label>
                                        <input type="password" name="password_confirmation" class="form-control" v-model="user.password_confirmation"
                                        :class="[{'is-invalid': errorFor('password_confirmation')}]">
                                        <v-errors :errors="errorFor('password_confirmation')"></v-errors>
                                    </div>
                                    <button class="btn btn-primary" type="submit" :disabled="loading" @click.prevent="register" >SIGN UP</button>
                                    <hr />
                                    <div class="text-nowrap">
                                        Already have an account?
                                        <router-link :to="{name: 'login'}" class="font-weight-bold">Log-in</router-link>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>



</template>
<style lang="scss" scoped>
    .login-container{
        width:90%;
        margin:4em auto 0 auto;

        i{
            color:#00549e;
        }
        h1{
            border-bottom: 3px solid #efefef;
            font-size:3em;
            margin: 0 0 1.6rem 0;
            color:#111;
        }
        h3 {
            color: #00549e;
            font-weight:600;
            border-bottom:1px solid #00549e;
            margin-bottom:1em;
            span{
                font-weight: 400;
                font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
            }
        }
        label{
            text-transform: uppercase;
            font-weight:600;
            margin-bottom:0;
            span{
                font-size: 0.75rem;
                color: #666666;
                font-weight:400;
                margin: 1rem 0;
            }
        }
    }

</style>

<script>
import validationErrors from '../components/mixins/validationErrors';
import { logIn, logOut } from "../components/utils/auth";
    export default{
        mixins: [validationErrors],
        data(){
            return{
                email:null,
                password:null,
                loading:false,
                success:false,
                verifyUser:false,
                verifyInvalid:false,
                hasNotVerified:false,
                hasArchive:false,
                errorLogin:false,
                user:{ },

            };
        },
        created(){
            if (this.$route.path=="/auth/login/verify"){
                  axios.get(`/api/verify/${this.$route.query.code}`).then(response=>{
                        if(response.data){
                            this.verifyUser=true;
                            var self=this;
                            setTimeout(function(){
                                self.verifyUser=false;
                            }, 5000);
                        }else{
                            this.verifyInvalid=true;
                            var self=this;
                            setTimeout(function(){
                                self.verifyInvalid=false;
                            }, 5000);
                        }

                    });
            }
            if(this.$route.query.errorLogin=='true'){
                this.errorLogin=true;
                var self=this;
                setTimeout(function(){
                    self.errorLogin=false;
                }, 3000);
            }
        },
        methods:{
            async dashboardLogin(){
                this.error=null;
                delete this.$route.query.errorLogin;
                this.errorLogin=false;
                try{
                    await axios.get(`/api/getUser/${this.email}`).then(response=>{
                        if(response.data != false){
                            if(response.data.email_verified_at ==null){
                                this.hasNotVerified=true;
                                var self=this
                                setTimeout(function(){
                                    self.hasNotVerified=false;
                                }, 5000);
                            };
                            if(response.data.archive ==1){
                                this.hasArchive=true;
                                var self=this
                                setTimeout(function(){
                                    self.hasArchive=false;
                                }, 5000);
                            };
                            if (response.data.email_verified_at !=null && response.data.archive ==0){
                                this.login();
                            }
                        }else{
                            this.errorLogin=true;
                            var self=this;
                            setTimeout(function(){
                                self.errorLogin=false;
                            }, 5000);
                        }


                    });
                }catch(error){
                    this.errorLogin=true;
                    var self=this;
                    setTimeout(function(){
                        self.errorLogin=false;
                    }, 4000);
                };

            },
            async login(){
                try {
                    await axios.get(`/sanctum/csrf-cookie`);
                    await axios.post("/login", {
                            email: this.email,
                            password: this.password
                        }).then(response=>{
                            axios.get(`/api/getUser/${this.email}`)
                            .then(response=> {
                                logIn(response.data.email,response.data.approve,response.data.role);
                                this.$store.dispatch("loadUser");
                                $(".show").removeClass("show");
                                this.$router.push({ name: "dashboard" });
                            });

                        });
                } catch (error) {
                    $(".show").removeClass("show");
                    this.$router.push({ name: "login", query: {errorLogin: "true"} });

                }
            },
            getUserInfo(email){

            },
            async register(){
                this.loading=true;
                this.errors=null;
                // delete this.$route.query.errorLogin;
                // this.errorLogin=false;
                try {

                    await axios.post(`/register`, this.user).then(response=>{
                        this.user={};
                        $("#userVerification").modal("show");
                    });
                } catch (error) {
                    this.errors=error.response && error.response.data.errors;
                }

                this.loading=false;
            }

        }
    };
</script>
