<template>
    <div>
        <!-- Breadcrumbs -->
        <div class="row pl-4 mt-3 breadcrumbs mw-100">
            <router-link to="/products" @click.native="clearFilters"
                >All Products&nbsp;&nbsp;</router-link
            >
            >
            <span
                class="font-weight-bold ml-2"
                v-if="!this.$route.query.subcat"
                >{{ breadcrumbs.crumb1 }}</span
            >
            <router-link
                v-if="
                    (categorySelected && subCategorySelected) ||
                        (categorySelected && this.$route.query.channel)
                "
                :to="{
                    name: 'products',
                    query: { cat: breadcrumbs.slug }
                }"
                class="ml-2"
            >
                {{ breadcrumbs.crumb1 }}
            </router-link>
            <span
                class="ml-2"
                v-if="
                    categorySelected &&
                        subCategorySelected &&
                        !this.$route.query.channel
                "
            >
                > {{ breadcrumbs.crumb2 }}</span
            >
            <router-link
                v-if="this.$route.query.channel && subCategorySelected"
                :to="{
                    name: 'products',
                    query: {
                        cat: breadcrumbs.slug,
                        subcat: breadcrumbs.slug2
                    }
                }"
                class="ml-2"
            >
                > {{ breadcrumbs.crumb2 }}</router-link
            >
            <!-- End Breadcrumbs -->
        </div>
        <div
            class="p-1 d-flex justify-content-between"
            v-if="filteredProducts"
            style="position:relative;"
        >
            <div>
                <p
                    class="m-0 ml-1 font-weight-bold text-secondary d-inline"
                    style="font-size: .7rem; font-family: Helvetica, Arial, sans-serif;"
                    v-if="filteredProducts.length"
                >
                    SHOWING {{ firstIndex }}{{ secondIndex }} OF
                    {{ filteredProducts.length }} RESULTS
                </p>
                <p
                    v-else
                    class="m-0 font-weight-bold text-secondary d-inline"
                    style="font-size: .75rem; font-family: Helvetica, Arial, sans-serif;"
                >
                    NO RESULTS FOUND
                </p>
            </div>
            <div class="ml-auto d-inline" id="main-pagination">
                <jw-pagination
                    :items="filteredProducts"
                    @changePage="onChangePage"
                    :labels="customLabels"
                    :pageSize="pageSize"
                    v-if="filteredProducts.length"
                    v-bind:class="pageClass"
                ></jw-pagination>
                <div class="currentPage" v-if="filteredProducts.length">
                    OF {{ numberOfPages }}
                </div>
            </div>
        </div>
        <div id="product-list-container" class="p-3">
            <div id="product-list" v-if="pageOfItems">
                <div
                    v-for="product in pageOfItems"
                    :key="product.id"
                    class="d-flex flex-column product justify-content-center mb-3"
                >
                    <product-list-item v-bind="product"></product-list-item>
                </div>
            </div>
        </div>
        <div
            class="p-1 d-flex justify-content-between"
            v-if="filteredProducts"
            style="position:relative;"
        >
            <div>
                <p
                    class="m-0 ml-1 font-weight-bold text-secondary d-inline"
                    style="font-size: .7rem; font-family: Helvetica, Arial, sans-serif;"
                    v-if="filteredProducts.length"
                >
                    SHOWING {{ firstIndex }}{{ secondIndex }} OF
                    {{ filteredProducts.length }} RESULTS
                </p>
                <p
                    v-else
                    class="m-0 font-weight-bold text-secondary d-inline"
                    style="font-size: .75rem; font-family: Helvetica, Arial, sans-serif;"
                >
                    NO RESULTS FOUND
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
const customLabels = {
    previous: "<",
    next: ">"
};

export default {
    data() {
        return {
            loading: false,
            pageOfItems: [],
            customLabels,
            firstIndex: null,
            secondIndex: null,
            pageSize: 25,
            slug2: null,
            channel: null
        };
    },
    computed: {
        pageClass() {
            if (this.numberOfPages > 9) {
                return "double-digit";
            }
        },
        subCategorySelected() {
            return this.$route.query.subcat != null;
        },
        categorySelected() {
            return this.$route.query.cat != null;
        },
        numberOfPages() {
            if (this.filteredProducts) {
                const size =
                    Math.floor(this.filteredProducts.length / this.pageSize) +
                    1;
                if (this.filteredProducts.length == this.pageSize) {
                    return 1;
                }
                return size;
            }
        },
        firstProductId() {
            if (this.filteredProducts) {
                return this.filteredProducts == undefined
                    ? null
                    : this.pageOfItems[0].id;
            }
        },
        selectedCategory() {
            if (this.$route.query.cat) {
                return this.categories.find(
                    category => category.slug == this.$route.query.cat
                );
            }
        },
        selectedSubcategory() {
            if (
                this.$route.query.cat &&
                this.$route.query.subcat &&
                this.selectedCategory
            ) {
                if (this.selectedCategory.children.length > 0) {
                    return this.selectedCategory.children.find(
                        subcategory =>
                            subcategory.slug == this.$route.query.subcat
                    );
                }
            }
        },
        filteredProducts() {
            let filtered = this.products;
            // if no filters show all products
            if (
                !this.$route.query.cat &&
                !this.$route.query.subcat &&
                // !this.$route.query.applications &&
                // !this.$route.query.brands &&
                !this.$route.query.s
            ) {
                return filtered;
            }
            //else check query params and filter products
            else {
                if (this.$route.query.s) {
                    let searchParam = this.$route.query.s;
                    filtered = this.products.filter(
                        product =>
                            // product.brand
                            //     .toLowerCase()
                            //     .includes(searchParam.toLowerCase().trim()) ||
                            product.prod_id.includes(
                                searchParam.toUpperCase().trim()
                            ) ||
                            // product.skus.includes(searchParam.trim()) ||
                            product.short_desc
                                .toLowerCase()
                                .includes(searchParam.toLowerCase().trim())
                    );
                } else {
                    if (this.$route.query.cat) {
                        if (
                            this.selectedCategory &&
                            this.selectedCategory.children.length > 0
                        ) {
                            const catFiltered = [];
                            filtered.forEach(product => {
                                if (
                                    product.category_id ==
                                    this.selectedCategory.id
                                ) {
                                    catFiltered.push(product);
                                }
                            });
                            this.selectedCategory.children.forEach(category => {
                                filtered.forEach(product => {
                                    if (product.category_id == category.id) {
                                        catFiltered.push(product);
                                    }
                                });
                            });
                            filtered = catFiltered;
                            this.$store.commit(
                                "setCategoryFilteredProducts",
                                filtered
                            );
                        }
                        if (
                            this.selectedCategory &&
                            this.selectedCategory.children.length < 1
                        ) {
                            filtered = this.products.filter(
                                product =>
                                    product.category_id ==
                                    this.selectedCategory.id
                            );
                            this.$store.commit(
                                "setCategoryFilteredProducts",
                                filtered
                            );
                        }
                    }
                    if (this.$route.query.subcat && this.selectedCategory) {
                        filtered = this.products.filter(
                            product =>
                                product.category_id ==
                                this.selectedSubcategory.id
                        );
                        this.$store.commit(
                            "setCategoryFilteredProducts",
                            filtered
                        );
                    }
                    // if (this.$route.query.applications) {
                    //     let applications = this.$route.query.applications.split(
                    //         ","
                    //     );
                    //     let applicationFiltered = [];
                    //     filtered.forEach(product => {
                    //         let productApplications = product.applications.split(
                    //             ","
                    //         );
                    //         productApplications.forEach(app => {
                    //             if (applications.includes(app.trim())) {
                    //                 if (
                    //                     applicationFiltered.indexOf(product) ===
                    //                     -1
                    //                 )
                    //                     applicationFiltered.push(product);
                    //             }
                    //         });
                    //     });

                    //     filtered = applicationFiltered;
                    // }
                    // if (this.$route.query.brands) {
                    //     let brandFiltered = [];
                    //     filtered.forEach(product => {
                    //         if (this.selectedBrands.includes(product.brand)) {
                    //             brandFiltered.push(product);
                    //         }
                    //     });
                    //     filtered = brandFiltered;
                    // }
                }
                return filtered;
            }
        },
        breadcrumbs() {
            let crumbs = { crumb1: null, crumb2: null, slug: null };
            let cat = {};
            if (this.$route.query.cat) {
                cat = this.selectedCategory;
                if (cat) {
                    crumbs.crumb1 = cat.name;
                    crumbs.slug = this.$route.query.cat;
                    if (this.$route.query.subcat) {
                        let subcategory = this.selectedSubcategory;
                        if (subcategory) crumbs.crumb2 = subcategory.name;
                    }
                }
            }

            return crumbs;
        },
        ...mapState([
            "products",
            "categories",
            "selectedBrands",
            "selectedChannels",
            "storedCategory"
        ])
    },
    methods: {
        onChangePage(pageOfItems) {
            this.updatePageOfItems(pageOfItems);
            this.updateFirstIndex();
        },
        updateFirstIndex() {
            if (this.filteredProducts && this.pageOfItems) {
                this.filteredProducts.forEach((product, index) => {
                    if (product.id == this.firstProductId) {
                        const first = index + 1;
                        const second = first + this.pageOfItems.length - 1;
                        this.firstIndex = first;

                        this.secondIndex = second > first ? `-${second}` : "";
                    }
                });
            }
        },
        updatePageOfItems(pageOfItems) {
            this.pageOfItems = pageOfItems;
        },
        clearFilters() {
            this.$store.dispatch("clearFilterSelections");
            this.$store.commit("setCategoryFilteredProducts", []);
        }
    }
};
</script>

<style lang="scss" scoped>
li.page-item.page-number.active .page-link {
    font-size: 12px !important;
    padding: 6px 13px !important;
    font-family: "Acumin Pro", Arial, Helvetica, sans-serif !important;
}
.breadcrumbs {
    font-size: 0.7rem;
    text-transform: uppercase;
    a {
        color: #333;
        &:hover {
            color: #248981;
        }
    }
}

#product-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 275px));
    padding: 3rem;
    align-items: stretch;
    column-gap: 1.5rem;
    @media screen and (max-width: 743px) {
        grid-template-columns: auto;
    }
}

.product {
    max-width: 90%;
    border-bottom: 2px solid #ccc;
    transition: all 0.4s ease-out;
    &:hover {
        border-bottom: 2px solid #245981;
    }
}
.currentPage {
    font-family: "Acumin Pro", Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    position: absolute;
    top: 13px;
    right: 1.7rem;
}
</style>
