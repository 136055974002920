var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"filters h-100"},[_c('h5',{staticClass:"text-uppercase text-primary"},[_vm._v("\n                Categories\n            ")]),_vm._v(" "),_c('ul',{staticClass:"list-style-none"},_vm._l((_vm.categories),function(category,index){return _c('li',{key:'cat' + index,class:{ active: _vm.isSelected(category.slug) }},[_c('router-link',{attrs:{"to":{
                            name: 'products',
                            query: { cat: category.slug }
                        }},nativeOn:{"click":function($event){return _vm.clearFilters(category.slug)}}},[_vm._v(_vm._s(category.name))]),_vm._v(" "),(
                            _vm.isSelected(category.slug) &&
                                category.children.length
                        )?_c('ul',{staticClass:"subcategories"},_vm._l((category.children),function(subcategory,index){return _c('li',{key:'sub' + index,class:{
                                active: _vm.subSelected(subcategory.slug)
                            }},[_c('router-link',{attrs:{"to":{
                                    name: 'products',
                                    query: {
                                        cat: category.slug,
                                        subcat: subcategory.slug
                                    }
                                }},nativeOn:{"click":function($event){return _vm.clearFilters($event)}}},[_vm._v("\n                                "+_vm._s(subcategory.name)+"\n                            ")])],1)}),0):_vm._e()],1)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }