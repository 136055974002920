<template>
    <div class="d-flex flex-column mt-3" v-if="images">
        <router-link
            :to="{ name: 'product', params: { id } }"
            @click.native="setProduct(id)"
        >
            <div
                class="img-container d-flex justify-content-center align-items-center mb-2"
            >
                <b-img
                    v-for="(image, index) in images.slice(0, 1)"
                    :key="index"
                    :src="`/images/products/${image.path}`"
                ></b-img>
            </div>
            <p class="brand">{{ brand }}</p>
            <p class="font-weight-normal description" v-html="short_desc"></p>
            <p class="prod-id">
                {{ prod_id }}
            </p>
        </router-link>

        <p
            v-if="$store.state.useCart"
            class=" mb-5"
            v-text="formatCurrency(price)"
        ></p>

        <!-- <p>Applications: {{ applications }}</p> -->
        <!-- <ul class="ml-3" v-if="features">
                    <li
                        v-for="(feature, index) in features.slice(0, 3)"
                        :key="'f' + index + prod_key"
                    >
                        {{ feature.description }}
                    </li>
                </ul> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false
        };
    },
    props: {
        short_desc: String,
        prod_desc: String,
        id: Number,
        prod_id: String,
        brand: String,
        applications: String,
        features: Array,
        images: Array,
        price: Number
    },
    methods: {
        setProduct(id) {
            this.$store.dispatch("setProduct", id);
        },
        formatCurrency(price) {
            price = price / 100;
            return price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
            });
        }
    }
};
</script>

<style lang="scss" scoped>
a {
    color: #111;
    font-weight: bolder;
}
li {
    font-size: 0.8rem;
}
h4,
p {
    font-family: "Acumin Pro", Helvetica, Arial, sans-serif;
}
.img-container {
    max-width: 250px;
    min-height: 200px;
    height: 200px;
    img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
}
.description {
    font-size: 1rem;
    min-height: 50px;
    font-weight: 100;
    font-family: "Acumin Pro", Helvetica, Arial, sans-serif;
}
.prod-id {
    color: teal;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0;
}
.brand {
    text-transform: uppercase;
    font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
    font-size: 1.625rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}
</style>
