<template>
    <div>
        <div
            class="row no-gutters"
            style="background-image: linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url('/images/headers/shop-page-1-1400x375.jpg'); height: 30vh; position: relative; background-position: center; background-size: cover; border-bottom: 2px solid white;"
        >
            <h1
                class="ml-3 text-light"
                style="position: absolute; bottom: 2rem; left: 2rem;"
            >
                Technical Support
            </h1>
        </div>
        <div class="row no-gutters d-flex justify-content-space-around">
            <section id="user-guides">
                <h3 class="border-bottom">User Guides</h3>
                <a
                    href="#fall-protection"
                    data-toggle="collapse"
                    @click="rotateIcon"
                    class="category-link"
                    ><i class="fas fa-angle-down mr-3"></i>Fall Protection</a
                >
                <div id="fall-protection" class="collapse content-container">
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/10095901-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/Safety-Works-Fall-Protection-Bucket-Instruction-Manual.pdf"
                            target="blank"
                            >10096889 - Fall Protection Kit (ANSI)</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/10095850-revised-2017-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/Safety-Works-Fall-Protection-Bucket-Instruction-Manual.pdf"
                            target="blank"
                            >10095850 - Fall Protection Kit (CSA)</a
                        >
                    </div>
                </div>

                <a
                    href="#hearing-protection"
                    data-toggle="collapse"
                    @click="rotateIcon"
                    class="category-link"
                    ><i class="fas fa-angle-down mr-3"></i>Hearing Protection</a
                >
                <div id="hearing-protection" class="collapse">
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/10059484-600x600-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2 d-inline"
                            href="/docs/SW-User-Guide-Ear-Plugs.pdf"
                            target="blank"
                            >10059484 - 100 - Pair Expandable Foam Ear Plugs in
                            Fishbowl Counter Dispenser</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SW10151070-600x600-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SW-User-Guide-Ear-Plugs.pdf"
                            target="blank"
                            >SW10151070 - 200-Pair Expandable Foam Ear Plugs</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00349-packaging-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SW-User-Guide-Ear-Plugs.pdf"
                            target="blank"
                            >SWX00349 - 80-Pair Bulk Pack Foam Ear Plugs</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/SWX00260-v2-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/SW-User-Guide-Bluetooth-Ear-Muffs.pdf"
                            target="blank"
                            >SWX00260 - Bluetooth, MP3 &amp; AM/FM Digital Radio
                            Ear Muffs</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/10121816-v2-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/SW-User-Guide-AM-FM-Ear-Muffs.pdf"
                            target="blank"
                            >10121816 - Digital Ear Muffs, MP3 &amp; AM/FM
                            Stereo</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/818074-foam-ear-plugs-w-case-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SW-User-Guide-Ear-Plugs.pdf"
                            target="blank"
                            >818074 - Foam Ear Plugs w/case</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/SWX00335-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/SW-User-Guide-Ear-Plugs.pdf"
                            target="blank"
                            >SWX00335 - Foam Ear Plugs - 4 Pairs</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/SWX00115-FR-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/SW-User-Guide-Foldable-Ear-Muffs.pdf"
                            target="blank"
                            >SWX00115 - Foldable Ear Muffs</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/SWX00334-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/SW-User-Guide-Pro-Series-Ear-Muffs.pdf"
                            target="blank"
                            >SWX00334 - Safety Works PRO Ear Muffs</a
                        >
                    </div>
                </div>
                <a
                    href="#respiratory-protection"
                    data-toggle="collapse"
                    @click="rotateIcon"
                    class="category-link"
                    ><i class="fas fa-angle-down mr-3"></i>Respiratory
                    Protection</a
                >
                <div id="respiratory-protection" class="collapse">
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00321_FrontView-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2 d-inline"
                            href="/docs/SW-User-Guide-Half-Mask-Respirator.pdf"
                            target="blank"
                            >SWX00321 - Safety Works PRO Multi-Purpose
                            Respirator, Half-Mask</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00319_FrontView-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SW-User-Guide-Half-Mask-Respirator.pdf"
                            target="blank"
                            >SWX00319 - Toxic Dust Respirator</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00320_FrontView-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SW-User-Guide-Half-Mask-Respirator.pdf"
                            target="blank"
                            >SWX00320 - Multi-Purpose Half Mask Respirator</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00318_FrontView-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SW-User-Guide-Half-Mask-Respirator.pdf"
                            target="blank"
                            >SWX00318 - Paint &amp; Pesticide Half Mask
                            Respirator</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00328-OMHfront-view-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SW-User-Guide-Full-Face-Respirator.pdf"
                            target="blank"
                            >SWX00328 - Safety Works PRO Multi-Purpose
                            Respirator, Full Facepiece</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00327-OMH-front-view-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SW-User-Guide-Full-Face-Respirator.pdf"
                            target="blank"
                            >SWX00327 - Paint &amp; Pesticide Respirator, Full
                            Facepiece</a
                        >
                    </div>
                </div>
            </section>
            <section id="data-sheets">
                <h3 class="border-bottom">Data Sheets</h3>
                <a
                    href="#fall-protection-ds"
                    data-toggle="collapse"
                    @click="rotateIcon"
                    class="category-link"
                    ><i class="fas fa-angle-down mr-3"></i>Fall Protection</a
                >
                <div id="fall-protection-ds" class="collapse content-container">
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/vertical-lifeline-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/10096605-50-ft-Rope-Vertical-Lifeline.pdf"
                            target="blank"
                            >10096605 - 50-ft Rope Vertical Lifeline</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/10096550-carabiner-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/10096550-Carabiner.pdf"
                            target="blank"
                            >10096550 - Carabiner</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/10096511_100966001.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/10096511-Manual-Rope-Grab.pdf"
                            target="blank"
                            >10096511 - Manual Rope Grab</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/10096456-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/10096540-Single-Use-Roof-Anchor.pdf"
                            target="blank"
                            >10096540 - Single-Use Roof Anchor</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/10096595.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/10096595-Trailing-Rope-Grab.pdf"
                            target="blank"
                            >10096595 - Trailing Rope Grab</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/10096491-harness-with-3-d-rings-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/10096481-and-10096491-3-D-Ring-Harness-Style-Vest.pdf"
                            target="blank"
                            >Harness with 3 D-rings</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/10096486-harness-with-back-d-ring-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/10096486-and-10096580-Harness-with-Single-D-Ring.pdf"
                            target="blank"
                            >Harness with single D-ring</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/reusable-roof-anchor-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/10102686-Reusable-Roof-Anchor.pdf"
                            target="blank"
                            >10102686 - Reusable Roof Anchor</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/fall-protection-lanyards-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/10096585-and-10096590-Shock-Absorbing-Lanyards.pdf"
                            target="blank"
                            >Shock-Absorbing Lanyard</a
                        >
                    </div>
                </div>
                <a
                    href="#respiratory-protection-ds"
                    data-toggle="collapse"
                    @click="rotateIcon"
                    class="category-link"
                    ><i class="fas fa-angle-down mr-3"></i> Respiratory
                    Protection</a
                >
                <div
                    id="respiratory-protection-ds"
                    class="collapse content-container"
                >
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00320_FrontView-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SWX00320-Half-Mask-Multi-Purpose-Respirator.pdf"
                            target="blank"
                            >SWX00320 - Multi-Purpose Half Mask Respirator</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/SWX00325-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/SWX00325-SWX00390-Respirator-Replacement-Cartridge-Multi-Purpose.pdf"
                            target="blank"
                            >SWX00325 - Multi-Purpose Respirator Replacement
                            Cartridges</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/10102483-FR-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/10103821-10102483-Disposable-Respirator-N95-Harmful-Dust-with-Exhalation-Valve.pdf"
                            target="blank"
                            >N95 - Harmful Dust Disposable Respirator w/
                            Exhalation Valve</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/10102485-F-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/10102485-Disposable-Respirator-N95-Harmful-Dust-with-Odor-Filter.pdf"
                            target="blank"
                            >10102485 - N95 Harmful Dust Disposable Respirator
                            w/ Odor Filter</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/10102481-FR-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/817633-10102481-Disposable-Respirator-N95-Harmful-Dust.pdf"
                            target="blank"
                            >N95 - Harmful Dust Disposable Respirators</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/10028560-F-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/10028549---10028560---10059526-Non-Toxic-Dust-Mask.pdf"
                            target="blank"
                            >Non-Toxic Dust Mask</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00318_FrontView-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SWX00318-Half-Mask-Paint-Pesticide-Respirator.pdf"
                            target="blank"
                            >SWX00318 - Paint &amp; Pesticide Half Mask
                            Respirator</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00327-OMH-front-view-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SWX00327-Full-Facepiece-Paint-Pesticide-Respirator.pdf"
                            target="blank"
                            >SWX00327 - Paint &amp; Pesticide Respirator, Full
                            Facepiece</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/SWX00322-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/SWX00322-Respirator-Replacement-Cartridge-Pre-Filter-Paint-Pesticide.pdf"
                            target="blank"
                            >SWX00322 - Paint &amp; Pesticide Respirator
                            Replacement Cartridges and Pre-Filters</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00323-stacked-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SWX00323-Respirator-Replacement-Pre-Filter-1.pdf"
                            target="blank"
                            >SWX00323 - Paint &amp; Pesticide Respirator
                            Replacement Pre-Filters</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00321_FrontView-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SWX00321-Half-Mask-PRO-Multi-Purpose-Respirator.pdf"
                            target="blank"
                            >SWX00321 - Safety Works PRO Multi-Purpose
                            Respirator, Half-Mask</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00328-OMHfront-view-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SWX00328-Full-Facepiece-PRO-Multi-Purpose-Respirator.pdf"
                            target="blank"
                            >SWX00328 - Safety Works PRO Multi-Purpose
                            Respirator, Full Facepiece</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img src="/images/SWX00326-400x400.jpg" alt="" />
                        <a
                            class="ml-2"
                            href="/docs/SWX00326-SWX00391-Respirator-Replacement-Cartridge-PRO-Multi-Purpose.pdf"
                            target="blank"
                            >SWX00326 - Safety Works PRO Multi-Purpose
                            Respirator Replacement Cartridges</a
                        >
                    </div>
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <img
                            src="/images/SWX00319_FrontView-400x400.jpg"
                            alt=""
                        />
                        <a
                            class="ml-2"
                            href="/docs/SWX00319-Half-Mask-Toxic-Dust-Respirator.pdf"
                            target="blank"
                            >SWXZ00319 - Toxic Dust Respirator</a
                        >
                    </div>
                </div>
            </section>
            <section id="consumer-notices">
                <h3 class="border-bottom">Consumer Notices</h3>
                <a
                    href="#respiratory-protection-cn"
                    data-toggle="collapse"
                    @click="rotateIcon"
                    class="category-link"
                    ><i class="fas fa-angle-down mr-3"></i> Respiratory
                    Protection</a
                >
                <div
                    id="respiratory-protection-cn"
                    class="collapse content-container"
                >
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <a
                            class="ml-2"
                            href="/docs/NOTICE-OF-RESPIRATORY-PRODUCT-CHANGE.pdf"
                            target="blank"
                            >Notice of Respiratory Product Change</a
                        >
                    </div>
                </div>
                <a
                    href="#prop65-cn"
                    data-toggle="collapse"
                    @click="rotateIcon"
                    class="category-link"
                    ><i class="fas fa-angle-down mr-3"></i> Prop 65</a
                >
                <div id="prop65-cn" class="collapse content-container">
                    <div class="row d-flex align-items-center p-2 flex-nowrap">
                        <a
                            class="ml-2"
                            href="https://us.pipglobal.com/en/distributor-tools/prop-65-portal/"
                            target="blank"
                            >Prop 65 Portal</a
                        >
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import validationErrors from "./../mixins/validationErrors";
export default {
    mixins: [validationErrors],
    data() {
        return {
            user: {
                name: null,
                email: null,
                subject: null,
                message: null
            },
            success: false,
            errors: null
        };
    },
    methods: {
        async sendMsg() {
            this.errors = null;
            try {
                await axios.post(`/api/sendMsg`, this.user).then(response => {
                    this.success = true;
                    var self = this;
                    setTimeout(function() {
                        self.success = false;
                    }, 3000);
                    this.user = {};
                });
            } catch (error) {
                this.errors = error.response && error.response.data.errors;
            }
        },
        rotateIcon(event) {
            $(event.target)
                .children("i")
                .toggleClass("rotate");
        }
    }
};
</script>

<style lang="scss" scoped>
h1 {
    font-size: 3.5rem;
}
label {
    font-family: "Acumin Pro", Helvetica, Arial, sans-serif;
}
.contact {
    padding: 3rem 2rem;
    @media screen and (max-width: 1604px) {
        padding: 3rem 0.5rem;
    }
}
section {
    padding: 3rem;
    min-width: 50%;
    max-width: 50%;
    @media screen and (max-width: 850px) {
        max-width: 100%;
        min-width: unset;
        display: block;
    }
    .content-container {
        border-bottom: 1px solid #ccc;
    }
    a {
        color: #248981;
    }
    a.category-link {
        font-size: 1.1rem;
        display: block;
        padding: 0.5rem;
        color: #248981;
        i {
            transition: all 0.3s ease-out;
            font-size: 1.5rem;
        }
    }
    img {
        max-width: 60px;
    }
}
.rotate {
    transform: rotate(180deg);
}
</style>
