<template>
    <div v-if="$store.state.cart.length > 0">
        <div
            class="row no-gutters bg-secondary d-flex flex-column justify-content-end p-4"
            style="border-bottom: 2px solid white; min-height: 15vh;"
        >
            <h1 class="text-light ml-3 text-center text-md-left">
                Checkout
            </h1>
        </div>
        <div class="row mt-3 p-5">
            <div class="col-lg-12">
                <h4>CART CONTENTS</h4>
                <table class="table p-3">
                    <thead>
                        <tr>
                            <th class="p-2">Item</th>
                            <th></th>
                            <th class="p-2 text-center">Qty</th>
                            <th></th>
                            <th class="p-2 text-center">Price</th>
                            <th class="p-2 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in cart" :key="item.id">
                            <td v-text="item.short_desc" class="p-2"></td>
                            <td class="text-right" style="width: 20px">
                                <i class="fas fa-minus"></i>
                            </td>
                            <td
                                v-text="item.quantity"
                                class="p-2 text-center"
                                style="width: 20px"
                            ></td>
                            <td class="text-left" style="width: 20px">
                                <i class="fas fa-plus"></i>
                            </td>
                            <td
                                v-text="cartLineTotal(item)"
                                class="p-2 text-center"
                            ></td>
                            <td class=" p-2 text-center">
                                <button
                                    class="btn btn-outline-danger btn-sm mx-auto"
                                    @click="
                                        $store.commit('removeFromCart', index)
                                    "
                                >
                                    Remove
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-2 text-right">Total Amount</td>
                            <td
                                class="p-2 text-center"
                                v-text="cartQuantity"
                            ></td>
                            <td class="p-2 text-center" v-text="cartTotal"></td>
                            <td class="p-2 text-center"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="p-3">
                <div class="d-flex flex-wrap mx-2 mt-5">
                    <div class="row w-100">
                        <h4 class="w-100">CUSTOMER INFORMATION</h4>
                        <div class="col-6">
                            <div class="input-group p-2">
                                <label for="first_name" class="mr-2"
                                    >First Name</label
                                >
                                <input
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    class="form-control"
                                    v-model="customer.first_name"
                                    :disabled="paymentProcessing"
                                />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group p-2">
                                <label for="last_name" class="mr-2"
                                    >Last Name</label
                                >
                                <input
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    class="form-control"
                                    v-model="customer.last_name"
                                    :disabled="paymentProcessing"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row w-50">
                        <div class="col-12">
                            <div class="input-group p-2">
                                <label for="email" class="mr-2">Email</label>
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    class="form-control"
                                    v-model="customer.email"
                                    :disabled="paymentProcessing"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-12">
                            <div class="input-group p-2">
                                <label for="address" class="mr-2"
                                    >Address</label
                                >
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    class="form-control"
                                    v-model="customer.address"
                                    :disabled="paymentProcessing"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-12">
                            <div class="input-group p-2">
                                <label for="address2" class="mr-2"
                                    >Address 2</label
                                >
                                <input
                                    type="text"
                                    id="address2"
                                    name="address2"
                                    class="form-control"
                                    v-model="customer.address2"
                                    :disabled="paymentProcessing"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6">
                            <div class="input-group p-2">
                                <label for="city" class="mr-2">City</label>
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    class="form-control"
                                    v-model="customer.city"
                                    :disabled="paymentProcessing"
                                />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group p-2">
                                <label for="state" class="mr-2">State</label>
                                <input
                                    type="text"
                                    id="state"
                                    name="state"
                                    class="form-control"
                                    v-model="customer.state"
                                    :disabled="paymentProcessing"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row w-50">
                        <div class="col-12">
                            <div class="input-group p-2">
                                <label for="zip_code" class="mr-2"
                                    >Zip Code</label
                                >
                                <input
                                    type="text"
                                    id="zip_code"
                                    name="zip_code"
                                    class="form-control"
                                    v-model="customer.zip_code"
                                    :disabled="paymentProcessing"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-12 p-2">
                        <div id="card-element" style="width: 98%;"></div>
                    </div>
                    <div class="row w-50">
                        <div class="col-12">
                            <div class="input-group p-4">
                                <button
                                    class="btn btn-success p-2 px-4 "
                                    @click="processPayment"
                                    :disabled="paymentProcessing"
                                    v-text="
                                        paymentProcessing
                                            ? 'Processing'
                                            : `Pay Now - ${cartTotal}`
                                    "
                                ></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div
            class="row no-gutters bg-secondary d-flex flex-column justify-content-end p-4"
            style="border-bottom: 2px solid white; min-height: 15vh;"
        >
            <h1 class="text-light ml-3 text-center text-md-left">
                SHOPPING CART IS EMPTY
            </h1>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
export default {
    data() {
        return {
            stripe: {},
            cardElement: {},
            customer: {
                first_name: "",
                last_name: "",
                email: "",
                address: "",
                city: "",
                state: "",
                zip_code: ""
            },
            paymentProcessing: false
        };
    },
    computed: {
        ...mapState(["useCart"]),
        cart() {
            return this.$store.state.cart;
        },
        cartQuantity() {
            return this.$store.state.cart.reduce(
                (acc, item) => acc + item.quantity,
                0
            );
        },
        cartTotal() {
            let amount = this.$store.state.cart.reduce(
                (acc, item) => acc + item.price * item.quantity,
                0
            );
            amount = amount / 100;
            return amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
            });
        }
    },
    async mounted() {
        if (this.useCart) {
            if (this.$store.state.cart.length > 0) {
                this.stripe = await loadStripe(`${process.env.MIX_STRIPE_KEY}`);
                const elements = this.stripe.elements();
                this.cardElement = elements.create("card", {
                    classes: {
                        base: "form-control"
                    }
                });
                this.cardElement.mount("#card-element");
            }
        }
    },
    methods: {
        cartLineTotal(item) {
            let price = item.price * item.quantity;
            price = price / 100;

            return price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
            });
        },
        addQuantity(item) {
            this.$store.commit("addToCart", item);
        },
        subtractQuantity(item) {
            this.$store.commit("removeFromCart", item);
        },
        itemQuantity(item) {
            let product = this.$store.state.cart.find(product => {
                product.id == item.id;
            });
            if (product) {
                console.log(product.quantity);
                return product.quantity;
            }
        },
        async processPayment() {
            //send payment info to Laravel & Stripe
            this.paymentProcessing = true;

            const {
                paymentMethod,
                error
            } = await this.stripe.createPaymentMethod(
                "card",
                this.cardElement,
                {
                    billing_details: {
                        name:
                            this.customer.first_name +
                            " " +
                            this.customer.last_name,
                        email: this.customer.email,
                        address: {
                            line1: this.customer.address,
                            line2: this.customer.address2,
                            city: this.customer.city,
                            state: this.customer.state,
                            postal_code: this.customer.zip_code
                        }
                    }
                }
            );

            if (error) {
                this.paymentProcessing = false;
                alert(error);
            } else {
                this.customer.payment_method_id = paymentMethod.id;
                this.customer.amount = this.cart.reduce(
                    (acc, item) => acc + item.price * item.quantity,
                    0
                );
                this.customer.cart = JSON.stringify(this.cart);

                axios
                    .post("/api/purchase", this.customer)
                    .then(response => {
                        this.paymentProcessing = false;

                        this.$store.commit("updateOrder", response.data);
                        this.$store.dispatch("clearCart");

                        this.$router.push({ name: "order.summary" });
                    })
                    .catch(error => {
                        this.paymentProcessing = false;
                        alert(error);
                    });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
label {
    min-width: 80px;
    text-align: right;
}
.fa-plus,
.fa-minus {
    color: #00549e;
    &:hover {
        cursor: pointer;
    }
}
</style>
