<template>
    <div>
        <div
            class="row no-gutters"
            style="background-image: linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url('/images/index-main-800-1.jpg'); height: 30vh; position: relative; background-position: center; background-size: cover; border-bottom: 2px solid white;"
        >
            <h1
                class="ml-3 text-light"
                style="position: absolute; bottom: 2rem; left: 2rem;"
            >
                Contact
            </h1>
        </div>
        <div class="row p-2 mt-2 no-gutters">
            <div class="col-lg-4 offset-lg-2 p-5">
                <div v-if="loading">
                    Sending Message...........
                </div>
                <div v-else>
                    <success v-if="success">
                        Your message has been sent !!!
                    </success>
                    <fatal-error v-if="errors">
                        There was an error sending your message. Please try again.
                    </fatal-error>

                    <form>
                        <p>
                            Please enter your information in the form below to
                            submit any questions or comments for us to review. Here
                            at Safety Works®, we are happy to provide an excellent
                            customer experience as well as making sure we get your
                            questions answered as soon as possible.
                        </p>
                        <div class="form-group">
                            <label
                                for="name"
                                class="font-weight-bold mb-0 text-uppercase"
                                >* Name:</label
                            >
                            <input
                                type="text"
                                name="name"
                                class="form-control"
                                v-model="user.name"
                                :class="[{ 'is-invalid': errorFor('name') }]"
                            />
                            <v-errors :errors="errorFor('name')"></v-errors>
                        </div>
                        <div class="form-group">
                            <label
                                for="email"
                                class="font-weight-bold mb-0 text-uppercase"
                                >* Email:</label
                            >
                            <input
                                name="email"
                                type="email"
                                class="form-control"
                                v-model="user.email"
                                :class="[{ 'is-invalid': errorFor('email') }]"
                            />
                            <v-errors :errors="errorFor('email')"></v-errors>
                        </div>
                        <div class="form-group">
                            <label
                                for="subject"
                                class="font-weight-bold mb-0 text-uppercase"
                                >* Subject:</label
                            >
                            <input
                                name="subject"
                                type="text"
                                class="form-control"
                                v-model="user.subject"
                                :class="[{ 'is-invalid': errorFor('subject') }]"
                            />
                            <v-errors :errors="errorFor('subject')"></v-errors>
                        </div>
                        <div class="form-group">
                            <label
                                for="message"
                                class="font-weight-bold mb-0 text-uppercase"
                                >* Message:</label
                            >
                            <textarea
                                name="message"
                                type="text"
                                class="form-control"
                                cols="30"
                                rows="10"
                                v-model="user.message"
                                :class="[{ 'is-invalid': errorFor('message') }]"
                            ></textarea>
                            <v-errors :errors="errorFor('message')"></v-errors>
                        </div>
                        <!-- Google Recaptcha -->
                        <div class="form-group col-md-12">
                            <div id="recaptcha" ></div>
                        </div>
                        <button class="btn  btn-primary " @click.prevent="sendMsg">
                            SEND
                        </button>
                    </form>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 p-4 mt-4">
                <div>
                    <p>
                        <img
                            src="/images/img-flag-usa.gif"
                            style="height: 14px;"
                            alt=""
                        />
                        <strong>Safety Works</strong><br />
                        <a class="text-dark" href="tel:8009697562"
                            >(800) 969-7562</a
                        >
                    </p>
                </div>
                <div>
                    <p>
                        <img
                            src="/images/img-flag-usa.gif"
                            style="height: 14px;"
                            alt=""
                        />
                        <strong>PIP Global Corporate Headquarters</strong><br />
                        25 British American Blvd.<br />
                        Latham, NY 12110<br />
                        <a class="text-dark" href="tel:8006471900"
                            >800-262-5755</a
                        >
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import validationErrors from "./../mixins/validationErrors";
export default {
    mixins: [validationErrors],
    data() {
        return {
            user: {
                name: null,
                email: null,
                subject: null,
                message: null,
                gRecaptchaResponse:null
            },
            success: false,
            errors: null,
            recaptchaWidgetId: null,
            loading:false,
            recaptchaLoaded: false,
        };
    },
    methods: {
        loadRecaptchaScript() {
            return new Promise((resolve, reject) => {
            if (window.grecaptcha) { // Check if reCAPTCHA is already loaded
                resolve();
            } else {
                // Create a script element to load reCAPTCHA
                const script = document.createElement('script');
                script.src = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit';
                script.async = true;
                script.defer = true;
                document.head.appendChild(script);

                // Define a global callback for when reCAPTCHA is loaded
                window.onRecaptchaLoad = () => {
                    console.log("reCAPTCHA loaded successfully.");
                    resolve();
                };

                // Error handling for script loading
                script.onerror = () => {
                reject(new Error("Failed to load the reCAPTCHA script"));
                };
            }
            });
        },
        renderRecaptcha() {
            var self = this;
            this.loadRecaptchaScript().then(() => {

                if (window.grecaptcha && window.grecaptcha.render) {
                    this.recaptchaWidgetId = grecaptcha.render('recaptcha', {
                        sitekey: '6Lc_NmwpAAAAAKDaGCZPI77ElMYB24oToP91yT3F',
                        callback: self.onRecaptchaSuccess,
                    });
                    // window.onRecaptchaSuccess = this.onRecaptchaSuccess;
                } else {
                    console.error("reCAPTCHA not loaded");
                }
            }).catch(error => {
            console.error("Error loading reCAPTCHA:", error.message);
            });
        },
        onRecaptchaSuccess(response) {
            this.user.gRecaptchaResponse = response;
        },
        async sendMsg() {
            this.loading=true;
            this.errors = null;
            try {
                await axios.post(`/api/sendMsg`, this.user).then(response => {
                    this.success = true;
                    var self = this;
                    this.loading=false;
                    setTimeout(function() {
                        self.success = false;
                    }, 5000);
                    this.user = {};
                });
            } catch (error) {
                this.loading=false;
                this.errors = error.response && error.response.data.errors;
            }
            if (window.grecaptcha && this.recaptchaWidgetId !== null) {
                this.renderRecaptcha();
            }
        }
    },
    mounted() {
        var self = this;
        this.$nextTick(() => {
            this.loadRecaptchaScript().then(() => {

            // Render reCAPTCHA widget here
                if (window.grecaptcha && window.grecaptcha.render) {
                    this.recaptchaWidgetId = grecaptcha.render('recaptcha', {
                        sitekey: '6Lc_NmwpAAAAAKDaGCZPI77ElMYB24oToP91yT3F',
                        callback: self.onRecaptchaSuccess,
                    });
                    // window.onRecaptchaSuccess = this.onRecaptchaSuccess;
                } else {
                    console.error("reCAPTCHA not loaded");
                }
            });
        });

    },
    beforeDestroy() {
        window.onRecaptchaSuccess = null;
    },
    watch: {
        'user.gRecaptchaResponse'(newValue) {
            console.log('gRecaptchaResponse captured');
        }


    }
};
</script>

<style lang="scss" scoped>
h1 {
    font-size: 3.5rem;
}
label {
    font-family: "Acumin Pro", Helvetica, Arial, sans-serif;
}
.contact {
    padding: 3rem 2rem;
    @media screen and (max-width: 1604px) {
        padding: 3rem 0.5rem;
    }
}
</style>
