<template>
    <div :name="name">
        <div class="row">
            <div class="col-md-12 px-3 pt-3">
                <h5 class="text-uppercase text-primary">{{ name }}</h5>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: String
    }
};
</script>

<style lang="scss" scoped>
h5 {
    font-size: 1.2rem;
    font-weight: 700;
    border-bottom: 1px solid #00549e;
    font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
}
</style>
