<template>
    <footer>
        <div class="footer-links-container row">
            <div class="col-md-3 mt-3">
                <p class="heading">RESOURCES</p>
                <ul class="links">
                    <li>
                        <a href="https://pipglobal.com/career" target="blank"
                            >Career Opportunities</a
                        >
                    </li>
                    <li>
                        <router-link :to="{ name: 'privacy' }"
                            >Privacy Policy</router-link
                        >
                    </li>
                    <!-- <li><a href="{{url('dashboard')}}">My Tools</a></li> -->
                </ul>
            </div>

            <!-- <div class="social col-md-3 mt-3">
                <p class="heading d-block">CONNECT WITH US</p>
                <a
                    href="https://www.facebook.com/WestChesterGear/"
                    target="blank"
                    ><i class="fab fa-facebook fa-2x p-1"></i
                ></a>
                <a href="https://www.twitter.com/westchestergear" target="blank"
                    ><i class="fab fa-twitter-square fa-2x p-1"></i
                ></a>
                <a
                    href="https://www.instagram.com/westchestergear/"
                    target="blank"
                    ><i class="fab fa-instagram-square fa-2x p-1"></i
                ></a>
                <a
                    href="https://www.youtube.com/westchestergloves"
                    target="blank"
                    ><i class="fab fa-youtube-square fa-2x p-1"></i
                ></a>
                <a
                    href="https://www.linkedin.com/company/west-chester-holdings"
                    target="blank"
                    ><i class="fab fa-linkedin fa-2x p-1"></i
                ></a>
            </div> -->
            <div class="contact col-md-3 mt-3">
                <div class="phone">
                    <p class="heading">call us today:</p>
                    <p class="number">
                        <a href="tel:5188610133">(518) 861-0133</a>
                    </p>
                </div>
                <div class="phone">
                    <p class="heading">customer service direct</p>
                    <p class="number">
                        <a href="tel:8552846800">(855) 284-6800</a>
                    </p>
                </div>
            </div>
            <div class="location col-md-3 mt-3">
                <div class="address">
                    <p class="heading">global headquarters</p>
                    <p class="address">
                        25 British American Blvd.<br />Latham, NY 12110
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="font-weight-light" style="font-size: .8rem;">
                    Copyright © Safety Works {{ new Date().getFullYear() }}. All
                    Rights Reserved.
                </p>
            </div>
        </div>
        <cookie-law theme="pip" :buttonText="text">
            <div slot-scope="props" class="w-100 row p-2">
                <p class="col-9">
                    This site uses cookies to provide you with the best
                    experience possible. By continuing to use this website
                    without modifying your settings, you are consenting to our
                    use of cookies. Learn more and how to manage your settings.
                    <router-link to="/cookies" class="cookie-link"
                        >More Information</router-link
                    >
                </p>
                <button
                    @click="props.accept"
                    class="btn btn-primary col-3 pipButton"
                >
                    I Accept
                </button>
            </div>
        </cookie-law>
    </footer>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
    data() {
        return {
            text: "Accept Cookies"
        };
    },
    components: { CookieLaw }
};
</script>

<style lang="scss">
.Cookie--pip {
    background: #111;
    padding: 2rem;
    font-size: 0.9rem;
}

.pipButton {
    line-height: 1;
    max-height: 35px;
    max-width: 100px;
    margin-left: auto;
    font-size: 0.9rem;
}

a {
    color: white;
    font-weight: normal;
}

a.cookie-link {
    color: #248981;
    margin-left: 0.5rem;
    &:hover {
        text-decoration: underline;
    }
}
</style>
