<template>
    <div>
        <div class="row no-gutters">
            <div class="w-100">
                <VueSlickCarousel v-bind="settings">
                    <div id="slide1" class="w-100 position-relative slide">
                        <div class="content-container position-absolute">
                            <div class="text-container">
                                <h1>
                                    SAFETY IS ALL WE DO
                                </h1>
                                <p>
                                    Our focus is exclusively on personal safety.
                                    It’s all we do, so we’re best positioned to
                                    assist retail professionals in selecting the
                                    best safety products for specific market
                                    needs.
                                </p>
                            </div>
                        </div>

                        <img
                            src="/images/headers/index-main-800-1-1400x700.jpg"
                            alt="Background image of construction worker reading level on metal beam"
                        />
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
        <div class="w-100" v-if="$store.state.useProducts">
            <div class="row no-gutters">
                <div class="industry col-lg-6 col-xl-4" id="ear-protection">
                    <div class="industry-text">
                        <h3>BRING THE NOISE</h3>
                        <p>
                            Our digital ear muffs help limit sound to below OSHA
                            dangerous threshold levels, even when connected to a
                            mobile device.
                        </p>
                        <router-link
                            :to="{
                                name: 'products',
                                query: { cat: 'hearing-protection' }
                            }"
                            class="btn btn-outline-light text-uppercase"
                            >View Products</router-link
                        >
                    </div>
                </div>
                <div class="industry col-lg-6 col-xl-4" id="head-protection">
                    <div class="industry-text">
                        <h3>EXCEPTIONAL STYLE &amp; FIT</h3>
                        <p>
                            Easy to use wheel ratchet adjustment system creates
                            a firm, comfortable fit with added security.
                        </p>
                        <router-link
                            :to="{
                                name: 'products',
                                query: { cat: 'head-face-protection' }
                            }"
                            class="btn btn-outline-light text-uppercase"
                            >View Products</router-link
                        >
                    </div>
                </div>
                <div class="industry col-lg-12 col-xl-4" id="eye-protection">
                    <div class="industry-text">
                        <h3>STYLE MEETS SAFETY</h3>
                        <p>
                            We offer a huge selection of safety glasses and
                            goggles for every project and price point.
                        </p>
                        <router-link
                            :to="{
                                name: 'products',
                                query: { cat: 'eye-protection' }
                            }"
                            class="btn btn-outline-light text-uppercase"
                            >View Products</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
    data() {
        return {
            settings: {
                dots: true,
                dotsClass: "slick-dots custom-dot-class",
                edgeFriction: 0.35,
                infinite: true,
                speed: 500,
                autoplay: true,
                controls: true,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        };
    },
    methods: {
        getLogo() {
            return "/images/pip-logo-white.svg";
        }
    },
    components: { VueSlickCarousel }
};
</script>

<style lang="scss" scoped>
.slick-slider {
    max-width: 100%;
    max-height: 75vh;
    #slide1 {
        display: block !important;
    }
    .slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    img {
        width: 100vw;
        max-width: 100%;
    }
    button.slick-arrow.slick-prev,
    button.slick-arrow.slick-next {
        z-index: 100 !important;
        &:before {
            font-size: 3rem !important;
        }
    }
    button.slick-arrow.slick-prev {
        left: 2rem !important;
        &:before {
            content: "\f104";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
        }
    }
    button.slick-arrow.slick-next {
        right: 2rem !important;
        &:before {
            content: "\f105";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
        }
    }
    .content-container {
        display: flex;
        max-height: 75vh;
        height: 100%;
        padding: 4rem;
        @media screen and (max-width: 1000px) {
            padding: 2rem;
        }

        .text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 25%;
            @media screen and (max-width: 1182px) {
                max-width: 33%;
            }

            h1 {
                font-family: "Oswald", sans-serif;
                font-size: 5rem;
                color: white;
                @media screen and (max-width: 1182px) {
                    font-size: 4rem;
                }
                @media screen and (max-width: 620px) {
                    font-size: 3rem;
                }
                @media screen and (max-width: 450px) {
                    font-size: 2.5rem;
                }
            }
            p {
                font-size: 1rem;
                margin: 0;
                color: white;
                font-weight: 100;
                text-align: left;
                line-height: 1.2;
                @media screen and (max-width: 1182px) {
                    display: none;
                }
            }
        }
    }
}
</style>
