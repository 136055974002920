<template>
    <div>
        <b-carousel id="hero-slider" background="white" controls indicators>
            <slot></slot>
        </b-carousel>
    </div>
</template>

<script>
export default {
    methods: {},
    created() {
        // const request = axios.get("/api/slides").then(response => {
        //     console.log(response);
        //     this.slides = response.data.data;
        // });
    }
};
</script>

<style lang="scss">
.carousel-caption {
    text-align: left;
    top: 12%;
    left: 5%;
    max-width: 40%;
    padding: 2em;
    right: unset;
    bottom: unset;

    h3 {
        font-size: 5rem;
        font-family: "Oswald", sans-serif;
        font-weight: 500;
        letter-spacing: 2px;
        @media (max-width: 1604px) {
            font-size: 4rem;
        }
        @media (max-width: 1235px) {
            font-size: 3rem;
        }
        @media screen and (max-width: 630px) {
            font-size: 2.5rem;
        }
    }
    p {
        @media (max-width: 991px) {
            display: none;
        }
    }
    @media (max-width: 1370px) {
        max-width: 50%;
    }
    @media (max-width: 1199px) {
        max-width: 80%;
        left: 15%;
        right: 15%;
        text-align: center;
    }
    @media (max-width: 630px) {
        max-width: 90%;
    }
}
</style>
